import React, { useEffect, useRef, useState } from "react";
import { EachQuestion } from "../service/QuestionService";
import '../styles/date.scss';

export default function Date({ updateAnswer, pregunta }: {
    updateAnswer: (idpregunta: string, pregunta?: string, respuesta?: string, idrespuesta?: string) => void
    pregunta: EachQuestion
}) {
    const dateRef = useRef<HTMLInputElement>(null);
    const [updateInputs, setUpdateInputs] = useState({
        day: '',
        month: '',
        year: ''
    });

    const setDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const ymd = e.currentTarget.value.split('-');
        if (ymd.length === 3) {
            setUpdateInputs({ year: ymd[0], month: ymd[1], day: ymd[2] });
            updateAnswer(pregunta.idpregunta, pregunta.enunciado, e.currentTarget.value);
        } else {
            setUpdateInputs({ year: '', month: '', day: '' });
            updateAnswer(pregunta.idpregunta);
        }
    }

    useEffect(() => {
        if (dateRef.current) {
            dateRef.current.addEventListener('click' , () => {
                dateRef.current?.showPicker();
            });
        }
    }, [])

    return (
        <div className="question-date">
            <div className="question-date__inner">

                <div className="question-date__each">
                    <p className="dates-label">
                        Día
                    </p>
                    <div className="dates-input">
                        <input
                            type="number"
                            placeholder='DD'
                            defaultValue={updateInputs.day ? updateInputs.day : ''}
                        />
                    </div>
                </div>

                <div className="date-brick"></div>

                <div className="question-date__each">
                    <p className="dates-label">
                        Mes
                    </p>
                    <div className="dates-input">
                        <input
                            type="number"
                            placeholder='MM'
                            defaultValue={updateInputs.month ? updateInputs.month : ''}
                        />
                    </div>
                </div>

                <div className="date-brick"></div>

                <div className="question-date__each">
                    <p className="dates-label">
                        Año
                    </p>
                    <div className="dates-input">
                        <input
                            type="number"
                            placeholder='YYYY'
                            defaultValue={updateInputs.year ? updateInputs.year : ''}
                        />
                    </div>
                </div>

            </div>
            <input
                type="date"
                className="main-input"
                ref={dateRef}
                onChange={setDate}
                onClick={(e) => e.currentTarget.showPicker()}
            />
        </div>
    )
}
