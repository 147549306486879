import React, { useState, useEffect, useRef } from "react";
import { useLoadScript, GoogleMap, MarkerF, Autocomplete, AutocompleteProps } from "@react-google-maps/api";
import { useFormikContext } from "formik";
import '../styles/components.scss'

interface LatLng {
    lat: number;
    lng: number;
}

export default function Map({ form, defaultMarker, defaultDirecction }: {
    defaultMarker?: LatLng
    defaultDirecction?: string
    form: boolean
}) {
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: `${apiKey}`,
        libraries: ['places'],
        language: 'es',
    })
    const autoCompleteRef = useRef<HTMLInputElement>(null);
    const [searchResult, setSearchResult] = useState<any>()
    const [marker, setMarker] = useState(defaultMarker);
    const [direction, setDirection] = useState(defaultDirecction || "");
    const [zoom, setZoom] = useState(defaultMarker ? 15 : 10)
    const formikContext = useFormikContext();

    useEffect(() => { 
        if (formikContext) {
            formikContext.setFieldValue('direccion', direction)
        }
    }, [direction])

    return isLoaded ? (
        <div className="map-container">
            <div 
                className="map-direction" 
                style={{
                    padding: form ? 0 : '1rem'
                }}
            >
                {form ? (
                    <Autocomplete
                        onPlaceChanged={() => {
                            if (searchResult != null) {
                                const place = searchResult.getPlace();

                                if (place.formatted_address) {
                                    setMarker({
                                        lat: place.geometry.location.lat(),
                                        lng: place.geometry.location.lng()
                                    })

                                    setZoom(15)
                                    setDirection(place.formatted_address)
                                }
                            }
                        }}
                        onLoad={(autocomplete) => {
                            setSearchResult(autocomplete)
                        }}
                    >
                        <input
                            type="text"
                            placeholder="Seleccione tu ubicación "
                            className="map-input"
                            id="map"
                            ref={autoCompleteRef}
                        />
                    </Autocomplete>
                ) : <>{direction}</>}
            </div>
            <GoogleMap
                zoom={zoom}
                center={{
                    lat: marker?.lat || 40.4637,
                    lng: marker?.lng || -3.7492,
                }}
                mapContainerClassName="map-style"
                onClick={(e) => {
                    if (form) {
                        setMarker({
                            lat: e.latLng?.lat() as number,
                            lng: e.latLng?.lng() as number 
                        })

    
                        const url = `https://maps.googleapis.com/maps/api/geocode/json?key=${apiKey}&latlng=${e.latLng?.lat()},${e.latLng?.lng()}&language=es`;
    
                        fetch(url)
                            .then((res) => res?.json())
                            .then((response) => {
                                if (response.results.length > 0) {
                                    const address = response.results[0].formatted_address
                                    
                                    if (autoCompleteRef.current) {
                                        autoCompleteRef.current.value = address;
                                    }
                                    setDirection(address as string);
                                }
                            })
                    }
                }}
            >
                {marker && (
                    <MarkerF
                        position={{
                            lat: marker.lat,
                            lng: marker.lng
                        }}
                    />
                )}
            </GoogleMap>
        </div>
    ) : <></>;
}
