import { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { selectMyCoursesById } from "../../redux/slice/CourseSlice";
import Table, { TableBody, TableButtons } from "../../components/Table";
import Routes, { useRoutes } from "../Routes";
import { generateFileUrl } from "../../service/Axios";
import ModuleService, { ModuleResponse } from "../../service/ModuleService";
import { AppDispatch, RootState } from "../../redux/store";
import { createModule, emptyModule } from "../../redux/slice/ModuleSlice";
import { AxiosError } from "axios";

function Modulo({ token, handleModule, deleteModule }: {
    token: string
    handleModule: (module: ModuleResponse) => any,
    deleteModule: () => any
}) {
    const { setErrors, setTabs, logout } = useRoutes();
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const course = useSelector((store) => selectMyCoursesById(store, pathname.split("/")[3]));

    const onBtnClick = async (id: string, type: number) => {
        try {
            const courseId = pathname.split("/")[3]
            const result = await ModuleService.fetchModules(id, token)
            const module_list = course?.modulos.map(modulo => {
                if (modulo.access) return modulo.idmodulocurso;
            })
            handleModule(result.mmodulo);

            if (type === 0) {
                navigate(
                    Routes.EVALUACIONES.replace(':id', courseId).replace(':id_eval', id),
                    { state: { course_name: course?.nombre, module_list }}
                )
            } else {
                navigate(
                    Routes.CONTENIDOS.replace(':id', courseId).replace(':id_cont', id), 
                    { state: { course_name: course?.nombre, module_list }}
                )
            }
        } catch (err) {
            const error = err as AxiosError
            
            if (error.message.includes("403")) {
                logout()
                navigate(Routes.INICIO)
            } else {
                setErrors(error.message)
            }
            
        }
    }

    useEffect(() => {
        if (!course) { 
            navigate(Routes.CURSOS) 
        } else {
            setTabs([{ name: course.nombre, url: pathname }])
        }

        deleteModule();
    }, [pathname])

    if (!course) return <></>

    return (
        <>
            <h2>{course.nombre}</h2>

            <Table header="Modulos">
                {course.modulos.map((modulo) => (
                    <TableBody 
                        bodyTitle={modulo.tituloWeb} 
                        bodyText={modulo.descripcionWeb}
                        image={modulo.imagenValue && generateFileUrl(modulo.imagenValue)}
                        key={modulo.idmodulocurso}
                    >
                        {modulo.access && (
                            <TableButtons 
                                buttons={[
                                    { id: 1, text: "Contenido", onClick: () => onBtnClick(modulo.idmodulocurso, 1)},
                                    { id: 0, text: "Test de evaluación", onClick: () => onBtnClick(modulo.idmodulocurso, 0)},
                                ]}  
                            />
                        )}
                    </TableBody>
                ))}
            </Table>
        </>

    );
}

const mapStateToProps = (state: RootState) => ({
    token: state.user.token,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    handleModule: (module: ModuleResponse) => dispatch(createModule(module)),
    deleteModule: () => dispatch(emptyModule()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modulo);