import { createSlice } from "@reduxjs/toolkit";
import { ModuleResponse } from "../../service/ModuleService";

const initialState: ModuleResponse = {
    idmodulocurso: "",
    idcurso: "",
    denominacion: "",
    tituloWeb: "",
    descripcionWeb: "",
    imagenValue: "",
    autoevaluaciones: [],
    contenidos: [],
    examenesCompletados: 0
}

export const moduleSlice = createSlice({
    name: 'module',
    initialState,
    reducers: {
        createModule: (state, action) => {
            state = action.payload
            return state
        },

        emptyModule: (state) => {
            state = initialState
            return state
        }
    }
})

export const { createModule, emptyModule } = moduleSlice.actions
export default moduleSlice.reducer