import React from "react";
import { FiExternalLink, FiLinkedin } from "react-icons/fi";
import { BiSolidChevronRight } from "react-icons/bi";
import { BsInstagram, BsTwitter, BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

export default function Footer() {
    return (
        <section className="App-footer">
            <div className="App-footer__inner">
                <h3>Contáctanos</h3>

                <br />

                <ul className="links">
                    <li className="phone">
                        Tel. <a href="tel:+34917444240"> +34 91 744 42 40 </a>
                    </li>
                    <li className="email hidden-xs">
                        <a href="mailto: codespa@codespa.org" data-original-title="Llamada telefónica">codespa@codespa.org</a>
                    </li>
                    <li className="address hidden-xs">
                        <a 
                            href="https://www.google.com/maps/place/Fundaci%C3%B3n+CODESPA/@40.4488126,-3.6635161,17z/data=!3m1!4b1!4m5!3m4!1s0xd422f2cbf057efd:0x1a1c3723687eb8ac!8m2!3d40.4488126!4d-3.6613274"
                            target="_blank"
                        > 
                            C/ Rafael Bergamín, 12, bajo 28043 Madrid <FiExternalLink size={20} color="#ef771e" />
                        </a>
                    </li>
                </ul>

                <br />

                <ul className="links">
                    <li>
                        <a href="https://us.codespa.org/" target="_blank">
                            <BiSolidChevronRight size={20} />
                            CODESPA America
                            <FiExternalLink size={20} color="#ef771e" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.fundaciocodespa.org/" target="_blank"> 
                            <BiSolidChevronRight size={20} />
                            Fundació CODESPA Catalunya
                            <FiExternalLink size={20} color="#ef771e" />
                        </a>
                    </li>
                </ul>

                <br />  
                <br />  

                <ul className="links social-links">
                    <li>
                        <a 
                            href="https://twitter.com/CODESPA" 
                            target="_blank" 
                            className="social-link"
                        ><BsTwitter /></a>
                    </li>

                    <li>
                        <a 
                            href="https://www.facebook.com/Codespa/" 
                            target="_blank" 
                            className="social-link"
                        ><FaFacebookF /></a>
                    </li>

                    <li>
                        <a 
                            href="https://www.linkedin.com/company/fundaci-n-codespa" 
                            target="_blank" 
                            className="social-link"
                        ><FiLinkedin /></a>
                    </li>
                    <li>
                        <a 
                            href="https://www.youtube.com/user/fcodespa" 
                            target="_blank" 
                            className="social-link"
                        ><BsYoutube /></a>
                    </li>

                    <li>
                        <a 
                            href="https://www.instagram.com/f.codespa" 
                            target="_blank" 
                            className="social-link"
                        ><BsInstagram /></a>
                    </li>
                </ul>
            </div>
        </section> 
    );
}
