
import { Field, Form, Formik, FormikProps } from "formik";
import React from "react";
import LoginService, { UserResponse } from "../../service/LoginService";
import Modal from "./Modal";
import '../../styles/inscripcion.scss'

interface LoginFormikValues {
    id: string;
    password: string;
}

interface LoginFormikErrors {
    id: string;
    password: string;
}

const initialValues: LoginFormikValues = {
    id: "",
    password: ""
}

export default function LoginModal({ show, handleShow, updateUser, backgroundClick }: {
    show: string,
    handleShow: () => void,
    updateUser: (param: UserResponse) => Promise<void>
    backgroundClick: boolean
}) {
    const registerUser = async (values: LoginFormikValues) => {
        try {
            const response = await LoginService.login(values);
            await updateUser(response);
        } catch (error) {
            console.log(error)
        }
    }

    const validate = (values: LoginFormikValues) => {
        const { id, password } = values;
        const errors: Partial<LoginFormikErrors> = {}

        if (!password) { errors.password = "* Por favor, introduzca su ID" }
        if (!id) { 
            errors.id = "* Por favor, Introduzca su dirección de correo electrónico " 
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(id)) {
            errors.id = "Dirección de correo electrónico inválida"
        }   

        return errors
    }

    return (
        <Modal show={show} onClick={handleShow} backgroundClick={backgroundClick}>
            <Formik
                initialValues={initialValues}
                validate={validate}
                onSubmit={registerUser}
            >
                {({ values, touched, errors }: FormikProps<LoginFormikValues>): React.ReactElement => (
                    <Form>
                        <div className="form-section">
                            <div className="form-input">
                                <label htmlFor="id">
                                    <span>Usuario</span>
                                    <Field 
                                        id="id"
                                        name="id"
                                        type="email"
                                        value={values.id}
                                        placeholder="Usuario"
                                        autoComplete="off"
                                        className={`${touched.id ? 'touched' : ''}${errors.id ? ' error' : ''}`}
                                    />
                                    {errors.id && touched.id && <span className="form-error">{errors.id}</span>}
                                </label>
                            </div>

                            <div className="form-input">
                                <label htmlFor="password">
                                    <span>Contraseña</span>
                                    <Field 
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={values.password}
                                        placeholder="Contraseña"
                                        autoComplete="off"
                                        className={`${touched.password ? 'touched' : ''}${errors.password ? ' error' : ''}`}
                                    />
                                    {errors.password && touched.password && <span className="form-error">{errors.password}</span>}
                                </label>
                            </div>

                            <br />
                            <br />

                            <button className="action-btn" type="submit">  
                                <span>Iniciar sesión</span>
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}
