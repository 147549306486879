import React, { useState } from "react";
import { Field, useFormikContext } from "formik";
import { Disclaimers } from "../../../service/CourseService";
import { generateFileUrl } from "../../../service/Axios";
import Modal from "../../../components/modal/Modal";

export default function Condiciones({ condiciones, tipo }: { condiciones: Disclaimers[], tipo: "condiciones" | "enlaceWeb" | "documento" }) {
    const [show, setShow] = useState("")
    const { getFieldProps, setFieldValue } = useFormikContext();

    const handleClick = () => {
        const value = getFieldProps("aceptarcondiciones").value as string[];
        const getCondicion = condiciones.filter(condicion => condicion.condiciones === show)[0]
        
        if (value.indexOf(getCondicion.blockchain) === -1) {
            setFieldValue("aceptarcondiciones", [ ...value, getCondicion.blockchain ])
        }

        setShow("")
    }
    
    if (tipo === "condiciones") {
        return (
            <>
                {condiciones.map(condicion => (
                    <div className="form-condicion" key={condicion.blockchain}>
                        <Field 
                            id={condicion.blockchain}
                            name={"aceptarcondiciones"}
                            type="checkbox"
                            value={condicion.blockchain}
                        />{` `}
                        <span 
                            style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '16px' }}
                            onClick={() => setShow(condicion.condiciones || "")}
                        >
                            {condicion.denominacion}
                        </span>
                    </div>
                ))}

                <Modal show={show} backgroundClick={false} customClass="increase-width">
                    <div style={{ textAlign: 'left' }}>
                        {show.split("\r\n").map(text => <p key={text} style={{ margin: '.4rem 0'}}>{text}</p>)}
                    </div>

                    <div 
                        className="cd-btn" 
                        style={{ marginTop: '3rem', maxWidth: '190px', justifyItems: 'center', margin: '3rem auto 0 auto' }}
                        onClick={handleClick}
                    >
                        Aceptar
                    </div>
                </Modal>
            </>
        )
    }

    return (
        <> 
            {(tipo === "documento" || tipo === "enlaceWeb") && condiciones.map(condicion => (
                <div className="form-condicion" key={condicion.blockchain}>
                    <Field 
                        id={condicion.blockchain}
                        name={"aceptarcondiciones"}
                        type="checkbox"
                        value={condicion.blockchain}
                    />{' '}
                    <span
                        style={{ textDecoration: 'underline', cursor: 'pointer', fontSize: '16px' }}
                        onClick={() => {
                            const targetUrl = tipo === "documento" ? generateFileUrl(condicion.documento as string) : condicion.enlaceWeb || ""
                            window.open(targetUrl, "_blank");
                        }}
                    >
                        {condicion.denominacion}
                    </span>
                </div>
            ))}
        </>
    );
}
