import { Dispatch, SetStateAction } from "react"
import { useOutletContext } from "react-router-dom"

export default {
    INICIO: '/',
    HOME: '/home',
    USUARIO: '/user-page',

    CURSOS_DISPONIBLES: '/home/cursos_disponibles',
    INSCRIPCION: "/home/inscripcion/:name",
    INSCRIPCION_ACEPTADA: "/inscripcion_aceptada",

    CURSOS: "/home/cursos",
    MODULO: "/home/cursos/:id",
    CONTENIDOS: "/home/cursos/:id/contenidos/:id_cont",
    EVALUACIONES: "/home/cursos/:id/evaluaciones/:id_eval",

    CUESTIONARIO: "/cuestionarios"
}

export function useRoutes() {
    return useOutletContext<{
        setTabs: Dispatch<SetStateAction<{ name: string, url: string, state?: {} }[]>>,
        setErrors: Dispatch<SetStateAction<string>>,
        logout: () => void
    }>()
}