import React from "react";
import { Formik, Form, Field } from "formik";
import { FormikProps } from "formik/dist/types";
import Button from "../../components/Button";
import Map from "../../components/Map";
import { InscriptionForm, InscriptionRequest } from "../../service/InscriptionService";
import { ModoCarga, Disclaimers } from "../../service/CourseService";
import Condiciones from "./components/Condiciones";

const formularioBeneficiarios: InscriptionForm[] = [
    {
        name: "nombre",
        type: "text",
        label: "Nombre",
        placeholder: "Nombre"
    },
    {
        name: "apellidos",
        type: "text",
        label: "Apellidos",
        placeholder: "Apellidos"
    },
    {
        name: "correoelectronico",
        type: "email",
        label: "Correo electrónico",
        placeholder: "Correo electrónico"
    },
    {
        name: "fechanacimiento",
        type: "date",
        label: "Fecha de Nacimiento",
        placeholder: "Fecha de Nacimiento",
        optional: true
    },
    {
        name: "dninie",
        type: "text",
        label: "DNI  (No. de identificación)",
        placeholder: "DNI",
        optional: true
    },
    {
        name: "genero",
        type: "select",
        label: "Sexo",
        placeholder: "Sexo",
        optional: true
    },
    {
        name: "telefono",
        type: "text",
        label: "Telefono",
        placeholder: "Telefono",
        optional: true
    },
    {
        name: "ocupacion",
        type: "text",
        label: "Ocupación",
        placeholder: "Ocupación",
        optional: true
    },
]

interface FormularioBeneficiarosFormikValues {
    nombre: string
    apellidos: string
    fechanacimiento: string
    dninie: string
    correoelectronico: string
    genero: number
    direccion: string
    telefono: string
    ocupacion: string
    aceptarcondiciones: []
}

interface FormularioBeneficiarosFormikErrors {
    nombre: string
    apellidos: string
    fechanacimiento: string
    dninie: string
    correoelectronico: string
    genero: string
    direccion: string
    telefono: string
    ocupacion: string
    aceptarcondiciones: string
    handleChange: any
}

const initialValues: FormularioBeneficiarosFormikValues = {
    nombre: "",
    apellidos: "",
    fechanacimiento: "",
    dninie: "",
    correoelectronico: "",
    genero: 0,
    direccion: "",
    telefono: "",
    ocupacion: "",
    aceptarcondiciones: []
}

type eachField = keyof FormularioBeneficiarosFormikValues

function FormularioBeneficiaros({ registerUser, condiciones }: { 
    registerUser: (data: InscriptionRequest) => void
    condiciones: Disclaimers[] | []
}) {
    const condicionDocumentos = condiciones.filter(condicion => condicion.modoCarga === ModoCarga.documento)
    const condicionEnlances = condiciones.filter(condicion => condicion.modoCarga === ModoCarga.enlance)
    const condicionTextos = condiciones.filter(condicion => condicion.modoCarga === ModoCarga.texto)

    const validate = (values: FormularioBeneficiarosFormikValues) => {
        const { nombre, apellidos, dninie, correoelectronico, genero, direccion, telefono, ocupacion, fechanacimiento, aceptarcondiciones } = values;
        const errors: Partial<FormularioBeneficiarosFormikErrors> = {};

        if (!nombre) { errors.nombre = "* Por favor, introduzca su nombre" }
        if (!apellidos) { errors.apellidos = "* Por favor, Introduzca su apellido" }
        // if (!dninie) { errors.dninie = "* Por favor, Introduzca su dni / nie" }
        // if (!genero) { errors.genero = "* Por favor, introduzca su genero" }
        if (!direccion) { errors.direccion = "* Por favor, Introduzca su dirección" }
        // if (!ocupacion) { errors.ocupacion = "* Por favor, introduzca su ocupación" }
        // if (!fechanacimiento) { errors.fechanacimiento = "* Por favor, introduzca su fecha de nacimiento" }

        if (aceptarcondiciones.length !== condiciones.length) { errors.aceptarcondiciones = "*Por favor, se deben aceptar todas las condiciones"}

        // if (!telefono) { 
        //     errors.telefono = "* Por favor, Introduzca su número de telefono" 
        // } else if (!/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(telefono)) {
        //     errors.telefono = "Número de telefono inválida"
        // }

        if (!correoelectronico) { 
            errors.correoelectronico = "* Por favor, Introduzca su dirección de correo electrónico " 
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(correoelectronico)) {
            errors.correoelectronico = "Dirección de correo electrónico inválida"
        }

        return errors;
    }

    return (
        <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={(values) => {
                registerUser({ 
                    ...values, 
                    nombreempresa: "", 
                    departamento: "", 
                    envio: false,
                    aceptarcondiciones: values.aceptarcondiciones.length === condiciones.length ? "on" : "off"
                })
            }}
        >
            {({ values, touched, errors }: FormikProps<FormularioBeneficiarosFormikValues>): React.ReactElement => (
                <Form>
                    <div className="form-section">
                        {formularioBeneficiarios.map(field => (
                            <div className="form-input" key={field.name}>
                                <label htmlFor={field.name} className="label">
                                    <span>
                                        {field.label}{!field.optional ? <span> *</span> : <></>}
                                    </span>

                                    {field.type !== "select" ? (
                                        <Field 
                                            id={field.name}
                                            name={field.name}
                                            type={field.type}
                                            value={values[field.name as eachField]}
                                            placeholder={field.placeholder}
                                            className={`${touched[field.name as eachField] ? 'touched' : ''}${errors[field.name as eachField] ? ' error' : ''}`}
                                            autoComplete="off"
                                            lang="es-ES"
                                        />
                                    ) : (
                                        <Field 
                                            as="select" 
                                            name="genero" 
                                            id="genero"
                                            className={`${touched.genero ? 'touched' : ''}${errors.genero ? ' error' : ''}`}
                                        >
                                            <option value={0}></option>
                                            <option value={33180}>Hombre</option>
                                            <option value={33181}>Mujer</option>
                                            <option value={33223}>No sabe / No contesta</option>
                                        </Field>
                                    )}

                                </label>
                                {errors[field.name as eachField] && touched[field.name as eachField] && <span className="form-error">{errors[field.name as eachField]}</span>}
                            </div>
                        ))}
                    </div>

                    <div className="form-input">
                            <label htmlFor="map"><span>Dirección</span></label>
                        <div className={`form-mapa${touched.direccion ? ' touched' : ''}${errors.direccion ? ' error' : ''}`}>
                            <Map form={true} />
                        </div>
                    </div>
                    {errors.direccion && touched.direccion && <span className="form-error">{errors.direccion}</span>}

                    {condiciones && (
                        <div className="form-condiciones" role="group" aria-labelledby="checkbox-group">
                            {condicionTextos && condicionTextos.length > 0 && <Condiciones condiciones={condicionTextos} tipo="condiciones" />}
                            {condicionEnlances && condicionEnlances.length > 0 && <Condiciones condiciones={condicionEnlances} tipo="enlaceWeb" />}
                            {condicionDocumentos && condicionDocumentos.length > 0 && <Condiciones condiciones={condicionDocumentos} tipo="documento" />}
                        </div>
                    )}
                    {errors.aceptarcondiciones && touched.aceptarcondiciones && <span className="form-error">{errors.aceptarcondiciones}</span>}

                    <div className="form-btn">
                        <Button text="INSCRIBETE" type="submit" />
                    </div>
                </Form>
            )}
        </Formik>
    );
}

export default FormularioBeneficiaros;
