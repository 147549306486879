import React, { useEffect, useState } from 'react';
import hand from '../../assets/hand.svg';
import Button from '../../components/Button';
import { updateActiveCourses } from '../../redux/slice/CourseSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Routes, { useRoutes } from '../Routes';
import FormularioPublico from './FormularioPublico';
import FormularioBeneficiaros from './FormularioBeneficiaros';
import InscriptionService, { InscriptionRequest } from '../../service/InscriptionService';
import CourseService, { CourseResponse, CourseType, PublicoObjetivo } from '../../service/CourseService';
import '../../styles/inscripcion.scss';
import { AxiosError } from 'axios';
import { BsCalendarEventFill } from 'react-icons/bs';
import { MdLocationOn, MdTimer } from 'react-icons/md';
import { FaCalendarAlt } from 'react-icons/fa';
import { generateFileUrl } from '../../service/Axios';
import { TableSponsors } from '../../components/Table';
import FormularioPersonal from './FormularioPersonal';
import { RootState, AppDispatch } from '../../redux/store';

function Inscripcion({ courses, handleActiveCourses }: { 
    courses: CourseResponse[], 
    handleActiveCourses: (param: CourseResponse[]) => any
}) {
    const { setErrors } = useRoutes()
    const navigate = useNavigate();
    const { state, pathname } = useLocation();
    const [course, setCourse] = useState<CourseResponse>()

    const registerUser = async (data: InscriptionRequest) => {
        try {
            const requestObj = {
                "cursotaller": course?.idcurso as string,
                ...data,
                envio: data.envio ? "on" : "off"
            }

            const result = await InscriptionService.register(requestObj)
            const accepted = {
                name: `${result.nombre.valueName} ${result.apellidos.valueName}`,
                course: course?.nombre
            }
        
            navigate(Routes.INSCRIPCION_ACEPTADA, { state: { ...accepted }})
        } catch (err) {
            const error = err as AxiosError
            setErrors(error.message);
        }
    }

    useEffect(() => {
        if (state && state.courseId) { 
            const newCourse = courses.filter(eachCourse => eachCourse.idcurso === state.courseId)
            
            if (newCourse.length === 1) {
                setCourse(newCourse[0])
            } else {
                navigate(Routes.CURSOS_DISPONIBLES) 
            }
        } else {
            if (pathname.split('/').length === 4) {
                const courseName = decodeURIComponent(pathname.split('/')[3])

                CourseService.fetchActiveCourses()
                    .then(response => {
                        if (!(response.cursos.length > 0)) {
                            setErrors("No hay cursos disponibles")
                        }

                        handleActiveCourses(response.cursos)
                        const getCourseName = response.cursos.filter(eachCourse => eachCourse.tituloWeb === courseName)
                       
                        if (getCourseName.length === 1) {
                            setCourse(getCourseName[0])
                        } else {
                            setErrors("Este curso no existe")
                        }
                    })
                    .catch(error => setErrors(error))
            } else {
                navigate(Routes.CURSOS_DISPONIBLES)
            }
        }
    }, []);

    if (!course) return <></>;

    return (
        <main className="App-inscripcion">
            <section className="section top-section">
                <section className="inscrip-obj">
                    <div className="inscrip-obj__img">
                        <img src={hand} alt="logo" />
                    </div>
                    <div className="inscrip-obj__table">
                        <p className="inscrip-table-header">OBJETIVOS</p>
                        <ul className="inscrip-table-list">
                            {course.objetivos.map(objetivo => (
                                <li key={objetivo.blockchain} className="inscrip-table-li">{objetivo.titulo}</li>
                            ))}
                        </ul>
                    </div>
                </section>

                <section className="inscrip-text">
                    <div className="inscrip-text__header">
                        <h2>{course.tituloWeb}</h2>
                        <Button 
                            text="Ver programa detallado" 
                            onClick={() => window.open(generateFileUrl(course.pdfCondiciones), '_blank')}
                        />
                    </div>

                    <div className="inscrip-text__body">
                        <p>{course.introduccion}</p>

                        {course.tipo === CourseType.Presencial && (
                            <>
                                <div className="inscrip-text__direction">
                                    <div className="inscrip-text__direction__header">
                                        <MdLocationOn size={25} />
                                        <span>Dirección: </span>
                                    </div>
                                    <a 
                                        href={`https://maps.google.com/maps?q=${course.ubicacion}`} 
                                        target='_blank' 
                                        className="inscrip-text__direction__link"
                                        rel="noreferrer"
                                    >{course.ubicacion}</a>
                                </div>

                                <div className="inscrip-text__date">
                                    <div className="each-date">
                                        <div className="each-date__text">
                                            <BsCalendarEventFill size={17} />
                                            <span>Fecha: </span>
                                        </div>
                                        <span>{course.fecha}</span>
                                    </div>

                                    {course.duracion && (
                                        <div className="each-date">
                                            <div className="each-date__text">
                                                <MdTimer size={22} />
                                                <span>Duración curso: </span>
                                            </div>
                                            <span>{course.duracion.split(",00")[0]} minutos</span>
                                        </div>
                                    )}

                                    <div className="each-date">
                                        <div className="each-date__text">
                                            <FaCalendarAlt size={19} />
                                            <span>Disponible (Desde - Hasta): </span>
                                        </div>
                                        <span>
                                            {course.disponibleDesde} - {course.disponibleHasta}
                                        </span>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </section>
            </section>

            <br />

            {/*  <hr className="divider" />  

            <section className="inscrip-question">
                <h1>¿A quién va dirigido?</h1>
                <p className="inscrip-paragraph">{course.aQuienVaDirigido}</p>
            </section>

            <section className="inscrip-question">
                <h1> ¿En que consiste?</h1>
                <p className="inscrip-paragraph">{course.enQueConsiste}</p>
            </section> */}

            <br />
            <hr />
            <br />
            <br />

            <section className="inscrip-register" id="registration">
                <h1>¿Cómo inscribirte?</h1>
                <div className="inscrip-register__div">
                    {
                        (course.publicoObjetivo === PublicoObjetivo.publico || course.publicoObjetivo === PublicoObjetivo.empresa)
                            ? <FormularioPublico registerUser={registerUser} setErrors={setErrors} condiciones={course.disclaimers} /> :
                        (course.publicoObjetivo === PublicoObjetivo.Beneficiarios) 
                            ? <FormularioBeneficiaros registerUser={registerUser} condiciones={course.disclaimers || []}  /> 
                            : <FormularioPersonal registerUser={registerUser} condiciones={course.disclaimers || []} />
                    }
                </div>
            </section>

            <TableSponsors colaboradores={course.colaboradores} />
        </main>
    )
}

const mapStateToProps = (state: RootState) => ({
    courses: state.courses.coursos_activos
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    handleActiveCourses: (param: CourseResponse[]) => dispatch(updateActiveCourses(param)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Inscripcion);