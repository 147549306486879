import { AxiosError } from "axios"
import Axios, { GenericResponse, GenericResponseWithoutContext } from "./Axios"

export enum QuestionType { 
    Fecha = "Fecha", 
    RespuestaTest = "Respuesta test", 
    Valores = "Valores del 1 al 10", 
    TextoLibre = "Texto libre"
};

export interface AnswerRequest {
    idpregunta: string
    respuesta?: string
    idrespuesta?: string
    pregunta?: string
}

export interface EachQuestion {
    idpregunta: string
    idcuestionario: string
    indicador: string
    indicadorasignado: string
    tipopregunta: QuestionType
    codigotipopregunta: string
    activa: string
    opcional: "on" | "off"
    enunciado: string
    introduccion: string
    blockchain: string
    respuestas: {
        idrespuesta: string
        idpregunta: string
        respuesta: string
        posicion: string
        valor: string
        blockchain: string
        id: string
    }[] | [],
    id: string
}

export interface Cuestionarios {
    taskId: string
    idcuestionario: string
    fecha: string
    denominacion: string
    tipocuestionario: string
    codigotipocuestionario: string
    tiempoestimado: string
    introduccion: string,
    observaciones: string
    blockchain: string
    preguntas: EachQuestion[],
    id: string
}

export interface Calificaiones {
    idPregunta: string
    idRespuesta: string
    pregunta: string
    respuesta: string
    idRespuestaCorrecta: string
    respuestaCorrecta: string
}

export interface EvaluacionesResponse extends GenericResponse {
    resultado: string
    calificaciones: Calificaiones[]
}

interface QuestionsResponse extends GenericResponse {
    cuestionario: Cuestionarios
}//QpV8LncS

class QuestionService {

    private axios: any;
    private token: string = ""

    constructor () {
        this.axios = Axios;
    }

    private static INSTANCE: QuestionService = new QuestionService();

    public static getInstance(): QuestionService {
        return this.INSTANCE;
    }

    public async fetchQuestions({ token, id }: { token: string, id: string | undefined }): Promise<QuestionsResponse> {
        try {
            const response: { data: QuestionsResponse, status: number } = await this.axios({
                method: "POST",
                url: "codespaformacion/cuestionario",
                data: { token, id }
            })

            const cuestionarioData = response.data;
            
            if (cuestionarioData.cuestionario === null) {
                throw new Error("Valor inválido del ID")
            }

            this.token = token

            return cuestionarioData;
        } catch (err) {
            const error = err as AxiosError
            throw new Error(error.message)
        }
    }

    public async saveEvaluaciones(answers: AnswerRequest[], taskId: string | undefined, idModulo: string): Promise<EvaluacionesResponse> {
        try {
            const response: { data: EvaluacionesResponse, status: number } = await this.axios({
                method: "POST",
                url: "/codespaformacion/resultadoautoevaluacion",
                data: {
                    token: this.token,
                    resultado: {
                        cuestionariotaskId: taskId,
                        idModulo,
                        fecha: this.generarFecha(),
                        respuestas: answers
                    }
                }
            })

            const result = response.data
            if (result.context.criticalError) {
                throw new Error(result.context.criticalMessage)
            }

            return result;
        } catch (err) { throw new Error(err as string)}
    }

    public async saveCuestionario(answers: AnswerRequest[], taskId: string | undefined, idcurso: string | undefined): Promise<number> {
        try {
            const response: { data: GenericResponseWithoutContext, status: number } = await this.axios({
                method: "POST",
                url: "/codespaformacion/resultadocuestionario",
                data: {
                    token: this.token,
                    resultado: {
                        cuestionariotaskId: taskId,
                        idcurso,
                        fecha: this.generarFecha(),
                        respuestas: answers
                    }
                }
            })

            const result = response.data

            if (result.criticalError) {
                throw new Error(result.criticalMessage)
            }

            return response.status;
        } catch (err) { throw new Error(err as string)}
    }

    private generarFecha(): string {
        const currentDate = new Date();
        return `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`
    }
}

export default QuestionService.getInstance();