import Axios, { GenericResponse } from "./Axios"
import { Cuestionarios } from "./QuestionService"

export interface ContenidosValue {
    idcontenidomodulo: string
    idmodulo: string
    contenidocurso: string
    disponible: string
    tituloportal: string
    descripcionportal: string
    observaciones: string
    blockchain: string
    contenido: {
        idcontenido: string
        fecha: string
        nombre: string
        autor: string
        tipologia: string
        codigotipologia: string
        formato: string
        codigoFormato: string
        version: string
        ubicacion: string
        enlaceurl: string
        fichero: string
        descripcion: string | null
        blockchain: string
        id: string
    },
    id: string
}

export interface AutoEvaluacionResponse {
    idautoevaluacionmodulo: string
    idmodulo: string
    fechadesde: string
    fechahasta: string
    denominacion: string
    autoevaluacion: string
    cuestionario: Cuestionarios,
    disponibleportal: string
    tituloportal: string
    descripcionportal: string
    blockchain: string
    noMoreTries: boolean
    id: string
    taskId: string
}

export interface ModuleResponse {
    idmodulocurso: string
    idcurso: string
    denominacion: string
    tituloWeb: string
    descripcionWeb: string
    imagenValue: string,
    examenesCompletados: number
    autoevaluaciones: AutoEvaluacionResponse[],
    contenidos: ContenidosValue[]
    access?: boolean
}

interface ModuleServiceResponse extends GenericResponse {
    mmodulo: ModuleResponse
}

class ModuleService {

    private axios: any;

    constructor() {
        this.axios = Axios;
    }

    private static INSTANCE = new ModuleService()

    public static getInstance(): ModuleService {
        return ModuleService.INSTANCE
    }

    public async fetchModules(id: string, token: string): Promise<ModuleServiceResponse> {
        try {
            const response: { data: ModuleServiceResponse } = await this.axios({
                method: "POST",
                url: "codespaformacion/detallemodulo",
                data: { token, id }
            })

            return response.data
        } catch (error) {
            throw new Error(error as string)
        }
    }
}

export default ModuleService.getInstance();