import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Codespa from '../../assets/codespa-logo.svg';
import Button from "../../components/Button";
import ScrollToHashElement from "../../components/ScrollToHashElement";
import ErrorModal from "../../components/modal/ErrorModal";
import { emptyCourse } from "../../redux/slice/CourseSlice";
import { emptyModule } from "../../redux/slice/ModuleSlice";
import { emptyUser } from "../../redux/slice/UserSlice";
import { AppDispatch, RootState } from "../../redux/store";
import Routes from "../Routes";
import Footer from "../../components/Footer";
import GenerateSvg from "../../components/GenerateSvg";

function Home({ deleteUser, deleteModule, deleteCourse, userId }: { 
    deleteUser: any
    deleteModule: any
    deleteCourse: any 
    userId: string
}) {
    const location = useLocation()
    const navigate = useNavigate()
    const [selected, setSelected] = useState(Routes.CURSOS_DISPONIBLES)
    const [tabs, setTabs] = useState<{ name: string, url: string, state: {} }[]>()
    const [errors, setErrors] = useState("");
    const checkRoute = (routes: string[]) => routes.some(route => route === selected)

    const logout = () => {
        deleteUser()
        deleteModule()
        deleteCourse()
        navigate(Routes.INICIO)
    }

    const goToUserPage = () => navigate(Routes.USUARIO)

    useEffect(() => {
        setSelected(location.pathname)

        if (!location.hash) {
            window.scrollTo(0, 0)
        }

        if (location.pathname === Routes.HOME && !(userId.length > 0)) {
            navigate(Routes.CURSOS_DISPONIBLES)
        }

        if (location.pathname === Routes.HOME && userId.length > 0) {
            navigate(Routes.CURSOS)
        }

        if (location.pathname === Routes.CURSOS_DISPONIBLES || 
            location.pathname === Routes.CURSOS) {
            setTabs([])
        }
    }, [location])

    return (
        <div className="App-afamilias">
            <ScrollToHashElement />
            <div className="App-header">
                <div className="App-logo">
                    <img src={Codespa} alt="logo de compaña" onClick={() => logout()} />

                    {userId && (
                        <Button 
                            textDom={(
                                <>
                                    <GenerateSvg file="puerta" color="white" />
                                    <span>Cerrar sesión</span>
                                </>
                            )}
                            style={{
                                width: "150px",
                                fontSize: "14px"
                            }}  
                            onClick={() => logout()}
                        /> 
                    )}
                </div>
                <nav className="App-nav">
                    <ul className="App-nav-ul">
                        <li className="App-nav-li">
                            <Link
                                to={Routes.CURSOS_DISPONIBLES}
                                className={checkRoute([ Routes.CURSOS_DISPONIBLES, Routes.INSCRIPCION ]) ? ' selected' : ''}
                            >
                                <GenerateSvg 
                                    file="casa" 
                                    color={checkRoute([ Routes.CURSOS_DISPONIBLES, Routes.INSCRIPCION ]) ? 'white' : 'black'}
                                />
                                CURSOS DISPONIBLES
                            </Link>
                        </li>

                        {userId && (
                            <li className="App-nav-li">
                                <Link
                                    to={Routes.CURSOS}
                                    className={checkRoute([ Routes.CURSOS ]) ? ' selected' : ''}
                                >
                                    <GenerateSvg file="libro" color={checkRoute([ Routes.CURSOS ]) ? 'white' : 'black'} />
                                    MIS CURSOS
                                </Link>
                            </li>
                        )}

                        {tabs?.map(tab => (
                            <li 
                                className="App-nav-li"
                                key={tab.url}
                            >
                                <Link
                                    to={tab.url}
                                    className={checkRoute([tab.url]) ? ' selected' : ''}
                                    state={tab.state}
                                >{tab.name}</Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>

            <div className="App-body">
                <div className="App-body__inner">
                    <Outlet context={{ setTabs, setErrors, logout }} />
                </div>

                <Footer />
            </div>


            <ErrorModal show={errors.replace("AxiosError: ", "")} setShow={setErrors} buttonType="green" />
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    userId: state.user.id
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    deleteModule: () => dispatch(emptyModule()),
    deleteUser: () => dispatch(emptyUser()),
    deleteCourse: () => dispatch(emptyCourse()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)