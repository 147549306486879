import React, { useEffect, useState } from "react";
import Map from "./Map";
import { BsCalendarEventFill } from 'react-icons/bs'
import { MdTimer } from 'react-icons/md';
import { FaCalendarAlt } from 'react-icons/fa'
import Button from "./Button";
import { generateFileUrl } from "../service/Axios";

export default function Table({ header, children }: { header: string, children: React.ReactNode }) {
    return (
        <div className="table">
            <div className="table-header">
                <h4>{header}</h4>
            </div>

            {children}
        </div>
    );
}

export const TableBody = ({ bodyTitle, bodyText, image, bar, customRightDom, children }: { 
    bodyTitle: string, 
    bodyText: string, 
    image?: string, 
    customRightDom?: React.ReactNode
    bar?: number
    children: React.ReactNode
}) => {
    const barStyle= {
        backgroundColor: `${bar === 100 ? '#00B2A9' : '#666666'}`,
        color: `${bar === 100 ? '#ffffff' : '#e4e4e4'}`
    } 

    return (
        <div className="table-body">
            <div className={`table-body__title${bar ? ' bar-title' : ''}`}>
                <h4>{bodyTitle}</h4>
                {bar !== undefined && !Number.isNaN(bar) && (
                    <div className="bar animate">
                        <div className="bar-inner" style={{ width: `${bar}%`, backgroundColor: barStyle.backgroundColor }}></div>
                        <span style={{ color: barStyle.color }}>Grado de avance: {bar}%</span>
                    </div>
                )}
            </div>

            <div className={`table-body__text${image || customRightDom ? ' image' : ''}`}>
                <p>{bodyText}</p>
                {(image || customRightDom) && (
                    <div className="table-image">
                        {image && <img src={image} alt="" />}
                        {customRightDom ?? <></>} 
                    </div>
                )}
            </div>

            {children}
        </div>
    )
}

export const TableMap = ({ lat, lng, direction }: { lat: number, lng: number, direction: string }) => (
    <div className="table-body__map">
        <Map 
            form={false}
            defaultMarker={{ lat, lng }}
            defaultDirecction={direction}
        />
    </div>
)

export const TableDate = ({ fecha, duration, from, until }: {
    fecha: string
    duration: string
    from: string,
    until: string
}) => (
    <div className="table-body__date">
        <div className="each-date">
            <div className="each-date__text">
                <BsCalendarEventFill size={17} />
                <span>Fecha: </span>
            </div>
            <span>{fecha}</span>
        </div>

        {duration && (
            <div className="each-date">
                <div className="each-date__text">
                    <MdTimer size={22} />
                    <span>Duración curso: </span>
                </div>
                <span>{duration.split(",00")[0]} minutos</span>
            </div>
        )}

        <div className="each-date">
            <div className="each-date__text">
                <FaCalendarAlt size={19} />
                <span>Disponible (Desde - Hasta): </span>
            </div>
            <span>
                {from} - {until}
            </span>
        </div>
    </div>
)

export const TableButtons = ({ buttons }: { 
    buttons: { 
        text: string 
        onClick: () => void
        id: string | number 
        className?: string 
        icon?: React.ReactNode 
    }[]
}) => (
    <div className="table-body__text-btn">
        {buttons.map(button => (
            <Button
                text={button.text}
                icon={button.icon ? button.icon : ''}
                className={button.className}
                onClick={() => {
                    if (button.onClick) button.onClick();
                }}
                key={button.id}
            />
        ))}
    </div>
)

export const TableSponsors = ({ colaboradores }: {
    colaboradores: {
        idcolaborador: string
        idcurso: string
        nombre: string
        tipoColaboracion: string
        logotipo: string
        blockchain: null
    }[]
}) => {
    type sponsorObject = { 
        Financiador: string[], 
        Colaborador: string[], 
        Avalador: string[] 
    }
    const [sponsors, setSponsors] = useState<sponsorObject>({ Financiador: [], Colaborador: [], Avalador: [] }) 

    useEffect(() => {
        const sponsorsTitle: sponsorObject = { Financiador: [], Colaborador: [], Avalador: [] }

        colaboradores.forEach(col => {
            const currentSponsor = col.tipoColaboracion as keyof sponsorObject
            sponsorsTitle[currentSponsor].push(col.logotipo)
        })
        setSponsors(sponsorsTitle)
    }, [])
    
    return (
        <div className="table-body__sponsors">
            {Object.keys(sponsors).map((sponsor, ind) => {
                if (sponsors[sponsor as keyof sponsorObject].length === 0) {
                    return <div key={ind} />
                }
                
                return (
                    <div className="table-sponsors" key={`${Math.random()}-${ind}`}>
                        <div className="table-sponsors__text">{sponsor} :</div>
    
                        <div className="table-sponsors__img">
                            {sponsors[sponsor as keyof sponsorObject].map(image => (
                                <img src={generateFileUrl(image)} key={image} />)
                            )}
                        </div>
                    </div>
                )
            })}
        </div>
    )
}