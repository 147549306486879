import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { AxiosError } from "axios";

import Date from "../../components/Date";
import Question from "../../components/Question";
import Ratings from "../../components/Ratings";
import Suggestions from "../../components/Suggestions";
import TextOptions from "../../components/TextOptions";
import { AppDispatch, RootState } from "../../redux/store";
import { AutoEvaluacionResponse } from "../../service/ModuleService";
import QuestionService, { AnswerRequest, Calificaiones, Cuestionarios, EvaluacionesResponse, QuestionType } from "../../service/QuestionService";
import Routes from "../Routes";
import CuestionarioAppreciationScreen from "./CuestionarioAppreciationScreen";
import CuestionarioSlideScreen from "./CuestionarioSlideScreen";
import CuestionarioWelcomeScreen from "./CuestionarioWelcomeScreen";
import ErrorModal from "../../components/modal/ErrorModal";
import LoginModal from "../../components/modal/LoginModal";
import { UserResponse } from "../../service/LoginService";
import { updateAnswers } from "../../redux/slice/AnswerSlice";
import '../../styles/cuestionarios.scss';

interface CuestionarioType extends Cuestionarios {
    answersId?: string;
}

const getCustomClass = (type: QuestionType): string => {
    if (type === QuestionType.Fecha) return "date-transform";
    if (type === QuestionType.RespuestaTest) return "text-transform";
    if (type === QuestionType.Valores) return "rating-transform";
    if (type === QuestionType.TextoLibre) return "observe-transform";

    return ""
}

function Cuestionario({ token, autoevaluaciones, idModulo, handleAnswers }: { 
    token: string, 
    autoevaluaciones: AutoEvaluacionResponse[], 
    idModulo: string 
    handleAnswers: ({ calificaciones, resultado }: { calificaciones: Calificaiones[], resultado: number }) => any
}) {
    const { pathname, search } = useLocation();

    const [cuestionario, setCuestionario] = useState<CuestionarioType | null>();
    const [searchResult, setSearchResult] = useState({
        token: "", id: "", idcurso: ""
    })
    const [welcomeScreen, setWelcomeScreen] = useState(true);
    const [login, setLogin] = useState("");
    const [complete, setComplete] = useState(false);
    const [answers, setAnwsers] = useState<AnswerRequest[]>([]);
    const [btnTransform, setBtnTransform] = useState(false);
    const [count, setCount] = useState(0);
    const [errors, setErrors] = useState("");

    const incrementCount = (ok?: string) => setCount((cnt: number) => {
        setBtnTransform(ok ? false : true);
        return cnt === cuestionario?.preguntas.length as number - 1  ? cnt : cnt + 1;
    });
    
    const decrementCount = (ok?: string) => setCount((cnt: number) => {
        setBtnTransform(ok ? false : true);
        return cnt === 0 ? cnt : cnt - 1;
    });

    const updateAnswer = (idpregunta: string, pregunta?: string, respuesta?: string, idrespuesta?: string) => {
        if (respuesta) {
            const currentAnswer: AnswerRequest = { idpregunta, respuesta, pregunta }
            if (idrespuesta) currentAnswer.idrespuesta = idrespuesta;

            const newAnswer = answers.filter(each => each.idpregunta !== idpregunta);
            setAnwsers([ ...newAnswer, currentAnswer ])

        } else {
            const newAnswer = answers.filter(each => each.idpregunta !== idpregunta);
            setAnwsers(newAnswer);
        }
    }

    const validateAnswers = (): number[] => {
        const requiredQuestions = cuestionario?.preguntas.filter(each => each.opcional === "off") || [];
        const answerIds = answers.map(each => each.idpregunta);

        let unanswered: number[] = [];

        for (let i = 0; i < requiredQuestions.length; i++) {
            const eachQuestions = requiredQuestions[i];

            if (answerIds.indexOf(eachQuestions.idpregunta) === -1) {
                unanswered.push(i + 1);
            } 
        }

        return unanswered; 
    }

    const getQuestions = async (id: string, newToken?: string, answersId?: string) => {
        try {
            const result = await QuestionService.fetchQuestions({ token: newToken? newToken : token, id });
            setCuestionario({ ...result.cuestionario, answersId });
            setLogin("")
        } catch (err) {
            const error = err as AxiosError;

            if (error.message.includes('403')) {
                setErrors("Valor inválido del Token")
                return;
            }

            setErrors(error.message.replace("Error:", ""));
        }
    }

    const updateToken = async (user: UserResponse) => {
        try {
            const searchObj = search.split("&")
            const searchResultObject = {
                id: searchObj[0].split("=")[1],
                idcurso: searchObj[1].split("=")[1]
            }

            setSearchResult({
                token: user.token,
                id: searchResultObject.id,
                idcurso: searchResultObject.idcurso
            })

            await getQuestions(searchResultObject.id, user.token)
        } catch (err) {
            const error = err as AxiosError;
            throw new Error(error.message.replace("Error:", ""));
        }
    }

    const onComplete = async () => {
        try {
            const unansweredQuestions = validateAnswers();
    
            if (unansweredQuestions.length > 0) {
                setErrors("Te has dejado preguntas sin contestar, revisa tus respuestas y vuelve a intentarlo");
                return;
            }

            let result: EvaluacionesResponse;

            if (!searchResult.idcurso) {
                result = await QuestionService.saveEvaluaciones(answers, cuestionario?.answersId, idModulo)

                if (result.calificaciones.length > 0) {
                    handleAnswers({
                        calificaciones: result.calificaciones,
                        resultado: parseFloat(result.resultado)
                    })
                }

            } else {
                const cuestionarioResponse = await QuestionService.saveCuestionario(answers, cuestionario?.taskId, searchResult.idcurso)

                if (cuestionarioResponse !== 200) {
                    setErrors("Tenemos un problema con la encuesta")
                    return;
                }
            }

            localStorage.removeItem("task_id"); 
            setSearchResult({ token: "", id: "", idcurso: "" })
            setComplete(true)    
            setWelcomeScreen(false)
        } catch (err) {
            // @ts-ignore
            setErrors(err.message.replace("Error:", ""));
        }
    };

    useEffect(() => {
        if (pathname === Routes.CUESTIONARIO) {
            document.title = "Cuestionario";
        }

        if (search.split("&").length === 2) {
            setLogin("show")
            localStorage.removeItem("task_id")
        }
        
        if (localStorage.getItem("task_id")) {
            const taskId = JSON.parse(localStorage.getItem("task_id") || "");
            const newCuestionario = autoevaluaciones.filter(cuestion => cuestion.taskId === taskId)[0];

            getQuestions(newCuestionario.cuestionario.taskId, undefined, newCuestionario.taskId);
        }
    }, [])

    return (
        <div className="App-cuestionario">
            {!errors && cuestionario && (
                <>
                    {!complete && (
                        <div className="stage-bar">
                            <div
                                className="stage-bar__inner"
                                style={{
                                    width: `${(100 / (cuestionario?.preguntas.length as number)) * answers.length}%`
                                }}
                            ></div>
                        </div>
                    )}

                    <CuestionarioWelcomeScreen
                        welcomeScreen={welcomeScreen}
                        complete={complete}
                        title={cuestionario?.denominacion}
                        onClick={() => setWelcomeScreen(false)}
                        body={cuestionario?.introduccion}
                        estimatedTime={cuestionario?.tiempoestimado}
                    />
                </>
            )}

            <CuestionarioSlideScreen 
                welcomeScreen={welcomeScreen} 
                complete={complete} 
                btnTransform={btnTransform} 
                count={count} 
                incrementCount={incrementCount} 
                decrementCount={decrementCount} 
                length={cuestionario?.preguntas.length as number}                
            >
                {cuestionario?.preguntas.map((pregunta, ind) => (
                    <Question
                        title={pregunta.enunciado}
                        questionNumber={ind + 1}
                        instruction={pregunta.introduccion}
                        customClass={getCustomClass(pregunta.tipopregunta)}
                        incrementCount={incrementCount}
                        onComplete={onComplete}
                        key={pregunta.idpregunta}
                        observable={ind === cuestionario?.preguntas.length as number - 1}
                    >
                        {pregunta.tipopregunta === QuestionType.Fecha && <Date updateAnswer={updateAnswer} pregunta={pregunta} />}
                        {pregunta.tipopregunta === QuestionType.RespuestaTest && <TextOptions updateAnswer={updateAnswer} incrementCount={incrementCount} pregunta={pregunta} />}
                        {pregunta.tipopregunta === QuestionType.Valores && <Ratings updateAnswer={updateAnswer} incrementCount={incrementCount} pregunta={pregunta} />}
                        {pregunta.tipopregunta === QuestionType.TextoLibre && <Suggestions updateAnswer={updateAnswer} pregunta={pregunta} />}
                    </Question>
                ))}
            </CuestionarioSlideScreen>

            <CuestionarioAppreciationScreen complete={complete} />

            <LoginModal 
                show={login} 
                handleShow={() => setLogin("")} 
                updateUser={updateToken} 
                backgroundClick={false}     
            />

            <ErrorModal show={errors} setShow={setErrors} buttonType="yellow" />
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    token: state.user.token,
    autoevaluaciones: state.module.autoevaluaciones,
    idModulo: state.module.idmodulocurso
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    handleAnswers: (param: any) => dispatch(updateAnswers(param))
})

export default connect(mapStateToProps, mapDispatchToProps)(Cuestionario)