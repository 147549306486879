import React from "react";
import Codespa from './assets/codespa-logo.svg'
import { Link } from "react-router-dom";
import Routes from './routes/Routes';
import './styles/App.scss'

export default function ErrorPage() {
    return (
        <div className="App-errorpage">
            <img src={Codespa} alt="logo de compaña" />

            <div className="App-errorpage__body">
                <div className="App-errorpage__body__inner">
                    <h1>404</h1>
                    <p>Esta página no se puede encontrar. Por favor, revise el enlace para asegurarse de que es correcto.</p>
                    <br />
                    <br />
                    <br />
                    <br />
                    <Link to={Routes.INICIO}>ir a la página de inicio</Link>
                </div>
            </div>
        </div>
    );
}
