import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Routes from './Routes';
import Codespa from '../assets/codespa-logo.svg';
import { AppDispatch, RootState } from '../redux/store';
import CourseService, { CourseResponse } from '../service/CourseService';
import { useState } from 'react';
import LoginModal from '../components/modal/LoginModal';
import { UserReduxResponse, UserResponse } from '../service/LoginService';
import { createUser } from '../redux/slice/UserSlice';
import { emptyCourse, updateActiveCourses, updateMyCourses } from '../redux/slice/CourseSlice';
import ErrorModal from '../components/modal/ErrorModal';
import { AxiosError } from 'axios';

function Root({ userId, handleUser, handleActiveCourses, handleMyCourses, deleteCourses }: {
    userId: string
    handleUser: (user: UserReduxResponse) => any
    handleActiveCourses: (courses: CourseResponse[]) => any
    handleMyCourses: (courses: CourseResponse[]) => any
    deleteCourses: () => any
}) {
    const navigate = useNavigate();
    const [show, setShow] = useState("");
    const [error, setError] = useState("");

    const getActiveCourses = async () => {
        try {
            const response = await CourseService.fetchActiveCourses();

            if (!(response.cursos.length > 0)) {
                throw new Error("No hay cursos disponibles")
            }

            handleActiveCourses(response.cursos)
            return response.cursos;
            
        } catch (err) {
            const error = err as AxiosError

            if (error.message.includes('403')) {
                setError("Credenciales incorrectas")
                return;
            }

            setError(error.message)
        }
    };

    const updateUser = async (user: UserResponse) => {
        try {
            const result = await CourseService.fetchMyCourses(user.token);
            await getActiveCourses();

            if (result.cursos.length === 0) {
                throw new Error("No tienes cursos")
            }

            const updatedResult = result.cursos.map(curso => {
                let currentModule: number | undefined; 

                curso.modulos.forEach((module, ind) => {
                    if (currentModule !== undefined) {
                        module.access = false
                        return
                    };

                    if (module.examenesCompletados === module.autoevaluaciones.length) {
                        module.access = true
                    } else if (module.examenesCompletados < module.autoevaluaciones.length) {
                        module.access = true
                        currentModule = ind
                        return
                    }
                })

                return curso;
            })

            handleMyCourses(updatedResult)
            handleUser({ 
                id:user.id, 
                firstName: user.firstName, 
                lastName: user.lastName, 
                token: user.token,
                lastPassUpdate: user.lastPassUpdate, 
                idioma: user.idioma 
            })
            
            setShow("")
        } catch (err) {
            const error = err as AxiosError
            setError(error.message)
        }
    }

    useEffect(() => {
        if (userId.length > 0) {
            navigate(Routes.CURSOS)
        } else {  
            deleteCourses()
        } 
    }, [userId])

    return (
        <div className="App-user">
            <div className="App-user__bg" />

            <div className="header">
                <img src={Codespa} alt="Codespa img" />
                <h2>Plataforma de formación de CODESPA</h2>
            </div> 

            <br />
            <br />

            <div className="App-user__text">
                <p>¿Quieres conocer nuestra oferta formativa?</p>

                <div className="App_user__text__btn">
                    <button 
                        className="action-btn"
                        onClick={() => getActiveCourses().then((course) => {
                            if (course !== undefined) {
                                navigate(Routes.CURSOS_DISPONIBLES)
                            }
                        })}
                    >Claro que si</button>
                    <button onClick={() => setShow("modal")} className="action-btn">Acceso como alumno</button>
                </div>
            </div>

            <LoginModal show={show} handleShow={() => setShow("")} updateUser={updateUser} backgroundClick={true} />

            <ErrorModal show={error} setShow={setError} buttonType='yellow' />
        </div>
    )
}

const mapStateToProps = (state: RootState) => ({
    userId: state.user.id
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    handleActiveCourses: (param: CourseResponse[]) => dispatch(updateActiveCourses(param)),
    handleMyCourses: (param: CourseResponse[]) => dispatch(updateMyCourses(param)),
    handleUser: (param: UserReduxResponse) => dispatch(createUser(param)),
    deleteCourses: () => dispatch(emptyCourse())
})

export default connect(mapStateToProps, mapDispatchToProps)(Root);