import React from 'react'
import { connect } from 'react-redux';
import Table, { TableBody, TableButtons, TableDate, TableMap, TableSponsors } from '../../components/Table';
import { RootState } from '../../redux/store';
import { generateFileUrl } from '../../service/Axios';
import { CourseResponse, CourseType } from '../../service/CourseService';
import OrlaSvg from "../../assets/Orla.svg";
import Routes from '../Routes';
import { useNavigate } from 'react-router-dom';
import '../../styles/contenidos.scss';

const MisCursos = ({ courses }: { courses: CourseResponse[] }) => {
    const navigate = useNavigate()

    const generateBtnsList = (course: CourseResponse) => {
        const defaultBtn = { 
            id: 0, 
            text: "Ver curso", 
            onClick: () => navigate(Routes.MODULO.replace(':id', course.idcurso), { state: { courseId: course.idcurso } }) 
        }
        const checkIfModuloIsCompleted = course.modulos.some(modulo => modulo.examenesCompletados < modulo.autoevaluaciones.length)

        return course.certificado && !checkIfModuloIsCompleted ? [
            {
                id: 1,
                text: "Certificado",
                onClick: () => window.open(course.certificado, "_blank"),
                icon: OrlaSvg,
                className: "eachBtn"
            },
            defaultBtn
        ] : [defaultBtn];
    }

    return (
        <main className="App-main App-cursos">
            <section className="table-cursos">
                <Table header="Mis cursos disponibles">
                    {courses.map(course => (
                        <TableBody 
                            key={course.idcurso} 
                            bodyTitle={course.tituloWeb} 
                            bodyText={course.introduccion} 
                            image={generateFileUrl(course.imagenWeb)}
                            bar={parseInt(`${parseFloat(course.gradodeavance) * 100}`)}
                        >
                            {course.tipo === CourseType.Presencial && (
                                <>
                                    <TableDate
                                        fecha={course.fecha} 
                                        duration={course.duracion} 
                                        from={course.disponibleDesde} 
                                        until={course.disponibleHasta} 
                                    />

                                    <TableMap 
                                        lat={parseFloat(course.ubicacioncord.split(',')[0])}
                                        lng={parseFloat(course.ubicacioncord.split(',')[1])}
                                        direction={course.ubicacion}
                                    />
                                </>
                            )}

                            <TableButtons buttons={generateBtnsList(course)}/>

                            <TableSponsors colaboradores={course.colaboradores} />
                        </TableBody>
                    ))}
                </Table>
            </section>
        </main>
    )
}

const mapStateToProps = (state: RootState) => ({
    courses: state.courses.mis_cursos
})

export default connect(mapStateToProps)(MisCursos);