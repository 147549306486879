import React from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import '../../styles/cuestionariosection.scss';

export default function CuestionarioSlideScreen({ children, welcomeScreen, complete, count, btnTransform, incrementCount, length, decrementCount }: { 
    children: React.ReactNode 
    welcomeScreen: boolean
    complete: boolean
    btnTransform: boolean
    count: number
    incrementCount: (param1?: string) => void
    decrementCount: (param1?: string) => void
    length: number
}) {
    return (
        <div className={`slides${!welcomeScreen ? ' is-visible' : ''}${complete ? ' complete' : ''}`}>
            <div
                className="slides-inner"
                style={{
                    transform: `translateY(${count > 0 ? (-100 * count) : 0}vh)`,
                    transition: count <= 0 || btnTransform ? 'transform 0.5s ease-in-out .1s' : `transform 0.5s ease-in-out .6s`
                }}
            >
                {children}
            </div>

            <div className="btns">
                <div className="btns-inner">
                    <div
                        className={`up-btn${count === 0 ? ' collapse' : ''}`}
                        onClick={() => decrementCount()}
                    >
                        <MdKeyboardArrowUp size={27} color='white' />
                    </div>

                    <div
                        className={`down-btn${count === length - 1 ? ' collapse' : ''}`}
                        onClick={() => incrementCount()}
                    >
                        <MdKeyboardArrowDown size={27} />
                    </div>
                </div>
            </div>
        </div>
    );
}
