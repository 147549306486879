import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createStateSyncMiddleware, initMessageListener } from "redux-state-sync";
import CourseReducer from './slice/CourseSlice';
import UserReducer from './slice/UserSlice';
import ModuleReducer from './slice/ModuleSlice';
import AnswerReducer from './slice/AnswerSlice';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer, PERSIST } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage
}

const reducer = combineReducers({ 
    courses: CourseReducer,
    user: UserReducer,
    module: ModuleReducer, 
    answer: AnswerReducer
})

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: { ignoreActions: true }})
            .concat([createStateSyncMiddleware({ blacklist: [PERSIST]}), thunk]),
})

const persistor = persistStore(store)
initMessageListener(store)

export { store, persistor }

// Types
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch