import React from "react";
import '../../styles/components.scss'

interface ModalType {
    show: string 
    onClick?: () => void
    children: any
    customClass?: string
    backgroundClick: boolean
}

export default function Modal({ show, onClick, children, customClass, backgroundClick }: ModalType) {
    return (
        <div className={`modal${show ? ' show' : ''}`}>
            <div 
                className="modal-background" 
                onClick={backgroundClick ? onClick : undefined}
            ></div>
            <div className={`modal-content${customClass ?` ${customClass}` : ''}`}>
                {children}
            </div>
        </div>
    );
}
