import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { CourseResponse } from "../../service/CourseService";

const initialState: {
    coursos_activos: CourseResponse[],
    mis_cursos: CourseResponse[],
} = {
    coursos_activos: [],
    mis_cursos: []
}

export const courseSlice = createSlice({
    name: 'courses',
    initialState,
    reducers: {
        updateActiveCourses: (state, action) => {
            state.coursos_activos = action.payload
            return state;
        },

        updateMyCourses: (state, action) => {
            state.mis_cursos = action.payload
            return state;
        },

        emptyCourse: (state) => {
            state = initialState
            return state;
        }
    },
})

export const { updateActiveCourses, updateMyCourses, emptyCourse } = courseSlice.actions

const selectAllCourses = (state: RootState) => state.courses;

export const selectActiveCoursesById = createSelector(
    [selectAllCourses, (state, courseId) => courseId],
    (courses, courseId) => courses.coursos_activos.find(course => course.idcurso === courseId)
)

export const selectMyCoursesById = createSelector(
    [selectAllCourses, (state, courseId) => courseId],
    (courses, courseId) => courses.mis_cursos.find(course => course.idcurso === courseId)
)

export default courseSlice.reducer;