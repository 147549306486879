import React, { useEffect, useState } from "react";
import { EachQuestion } from "../service/QuestionService";
import QuestionOption from "./QuestionOption";

export default function TextOptions({ incrementCount, updateAnswer, pregunta }: {
    incrementCount: (param1?: string) => void
    updateAnswer: (idpregunta: string, pregunta?: string, respuesta?: string, idrespuesta?: string) => void
    pregunta: EachQuestion
}) {
    const [clicked, setClicked] = useState('');
    const letter = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L']

    useEffect(() => {
        if (clicked) {
            incrementCount('ok')
            const respuesta = pregunta.respuestas.filter((_, ind) => ind === letter.indexOf(clicked))
            updateAnswer(pregunta.idpregunta, pregunta.enunciado, respuesta[0].respuesta, respuesta[0].idrespuesta);
        } else {
            updateAnswer(pregunta.idpregunta);
        }
    }, [clicked])

    return (
        <div className="text">
            {pregunta.respuestas.map((respuesta,  ind) => (
                <QuestionOption 
                    key={respuesta.id}
                    letter={letter[ind]}
                    option={respuesta.respuesta}
                    clicked={clicked}
                    setClicked={setClicked}
                />
            ))}
        </div>
    )
}
