import Axios, { GenericResponse } from "./Axios";
import { ModuleResponse } from "./ModuleService";

export interface CourseResponse {
    taskId: string;
    idcurso: string
    nombre: string
    tipo: string
    inscripcionPreviaDias: string
    impartidoPor: string
    fecha: string
    duracion: string
    disponibleDesde: string
    disponibleHasta: string
    tituloWeb: string
    introduccion: string
    objetivoDelCurso: string
    imagenWeb: string
    aQuienVaDirigido: string
    ubicacion: string
    ubicacioncord: string
    acceso: string
    gradodeavance: string
    publicoObjetivo: PublicoObjetivo,
    lineaDeEspecializacion: string
    aforo: string
    enQueConsiste: string
    nombreIndicador1: null
    nombreIndicador2: null
    nombreIndicador3: null
    nombreAutoevaluacion1: null
    nombreAutoevaluacion2: null
    nombreAutoevaluacion3: null
    pdfCondiciones: string
    certificado: string
    modulos: ModuleResponse[]
    colaboradores: {
        idcolaborador: string
        idcurso: string
        nombre: string
        tipoColaboracion: string
        logotipo: string
        blockchain: null
    }[]
    examenes: {
        idcuestionariocurso: string
        idcurso: string
        fechadesde: string
        fechahasta: string
        denominacion: string | null
        cuestionario: string | null
        disponible: string
        tituloportal: string
        descripcionportal: string
        blockchain: string
        id: string
    }[]
    objetivos: {
        taskId: string
        idobjetivocurso: string
        idcurso: string
        titulo: string
        descripcionobjetivo: string
        blockchain: string
    }[]
    disclaimers: Disclaimers[]
}

export interface Disclaimers {
    idCurso: string
    idDisclaimer: string
    fecha: string
    denominacion: string
    tituloEnlace: null | string
    modoCarga: ModoCarga
    enlaceWeb: null | string
    documento: null | string
    condiciones: null | string,
    blockchain: string
    id: string
}

export enum PublicoObjetivo {
    Beneficiarios="Beneficiarios",
    publico="Público en general",
    empresa="Responsables de empresas"
};

export enum CourseType {
    Presencial="Presencial",
    Virtual="Virtual"
}

export enum ModoCarga {
    texto="33213", documento="33212", enlance="33211"
}

interface CourseServiceResponse extends GenericResponse {
    cursos: CourseResponse[]
}

class CourseService {

    private axios: any;
    private token: string = "EmMMa0VJm6rRjfzvxKE8CUXXX"
    
    constructor() {
        this.axios = Axios;
    }

    private static INSTANCE: CourseService = new CourseService();

    public static getInstance(): CourseService {
        return CourseService.INSTANCE
    }

    public async fetchActiveCourses(): Promise<CourseServiceResponse>  {
        try {
            const result: { data: CourseServiceResponse } = await this.axios({
                url: "codespaformacion/cursosactivos",
                data: {
                    token: this.token
                },
                method: "POST",
            })
            
            return result.data;
        } catch (error) {   
            throw new Error(error as string)
        }
    }

    public async fetchMyCourses(token: string): Promise<CourseServiceResponse> {
        try {
            const response: { data: CourseServiceResponse } = await this.axios({
                url: "/codespaformacion/miscursos",
                data: { token },
                method: "POST"
            })

            return response.data;
        } catch (error) {
            throw new Error(error as string)
        }
    }
}

export default CourseService.getInstance();