import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { FormikProps } from "formik/dist/types";
import Button from "../../components/Button";
import Map from "../../components/Map";
import InscriptionService, { DocumentRequest, DocumentResponse, InscriptionForm, InscriptionRequest } from "../../service/InscriptionService";
import { AxiosError } from "axios";
import { ModoCarga, Disclaimers } from "../../service/CourseService";
import Condiciones from "./components/Condiciones";

const formularioPublico: InscriptionForm[] = [
    {
        name: "nombre",
        type: "text",
        label: "Nombre",
        placeholder: "Nombre"
    },
    {
        name: "apellidos",
        type: "text",
        label: "Apellidos",
        placeholder: "Apellidos"
    },
    {
        name: "correoelectronico",
        type: "email",
        label: "Correo electrónico",
        placeholder: "Correo electrónico"
    },
    {
        name: "nombreempresa",
        type: "text",
        label: "Nombre de la empresa",
        placeholder: "Nombre de la empresa"
    },
    {
        name: "departamento",
        type: "text",
        label: "Departamento",
        placeholder: "Departamento"
    },
    {
        name: "fechanacimiento",
        type: "date",
        label: "Fecha de Nacimiento",
        placeholder: "Fecha de Nacimiento",
        optional: true
    },
    {
        name: "dninie",
        type: "text",
        label: "DNI (No. de identificación)",
        placeholder: "DNI",
        optional: true
    },
    {
        name: "telefono",
        type: "number",
        label: "Telefono",
        placeholder: "Telefono",
        optional: true
    },
    {
        name: "ocupacion",
        type: "text",
        label: "Cargo",
        placeholder: "Cargo",
        optional: true
    },
    {
        name: "curriculum",
        type: "file",
        label: "Curriculum Vitae",
        placeholder: "Curriculum Vitae",
        optional: true,
    },
    {
        name: "carta",
        type: "file",
        label: "Carta de motivación",
        placeholder: "Carta de motivación",
        optional: true,
    }
]

interface FormularioPublicoFormikValues {
    nombre: string
    apellidos: string
    dninie: string
    fechanacimiento: string
    correoelectronico: string
    nombreempresa: string
    telefono: string
    departamento: string
    direccion: string
    ocupacion: string
    curriculum: string
    carta: string
    envio: boolean
    aceptarcondiciones: string[]
}

interface FormularioPublicoFormikErrors {
    nombre: string
    apellidos: string
    dninie: string
    correoelectronico: string
    fechanacimiento: string
    nombreempresa: string
    telefono: string
    departamento: string
    direccion: string
    ocupacion: string
    curriculum: string
    carta: string
    aceptarcondiciones: string
    handleChange: any 
}

type eachField = keyof FormularioPublicoFormikValues;

const initialValues: FormularioPublicoFormikValues = {
    nombre: "",
    apellidos: "",
    dninie: "",
    fechanacimiento: "",
    correoelectronico: "",
    nombreempresa: "",
    telefono: "",
    departamento: "",
    direccion: "",
    ocupacion: "",
    curriculum: "",
    carta: "",
    aceptarcondiciones: [],
    envio: false
}

function FormularioPublico({ registerUser, setErrors, condiciones }: { 
    registerUser: (data: InscriptionRequest) => void
    setErrors: React.Dispatch<React.SetStateAction<string>>
    condiciones: Disclaimers[] | []
}) {
    const [curriculumVitae, setCurriculumVitae] = useState({} as File)
    const [cartaDeMotivacion, setCartaDeMotivacion] = useState({} as File)

    const condicionDocumentos = condiciones.filter(condicion => condicion.modoCarga === ModoCarga.documento)
    const condicionEnlances = condiciones.filter(condicion => condicion.modoCarga === ModoCarga.enlance)
    const condicionTextos = condiciones.filter(condicion => condicion.modoCarga === ModoCarga.texto)

    const validate = (values: FormularioPublicoFormikValues) => {
        const { nombre, apellidos, correoelectronico, nombreempresa, departamento, direccion, aceptarcondiciones } = values;
        const errors: Partial<FormularioPublicoFormikErrors> = {};

        if (!nombre) { errors.nombre = "* Por favor, introduzca su nombre" }
        if (!apellidos) { errors.apellidos = "* Por favor, Introduzca su apellido" }
        if (!nombreempresa) { errors.nombreempresa = "* Por favor, Introduzca el nombre de la Empresa" }
        if (!departamento) { errors.departamento = "* Por favor, Introduzca su departamento" }
        if (!direccion) { errors.direccion = "* Por favor, Introduzca su dirección" }
                        
        if (aceptarcondiciones.length !== condiciones.length) { errors.aceptarcondiciones = "*Por favor, se deben aceptar todas las condiciones"}
        if (!correoelectronico) { 
            errors.correoelectronico = "* Por favor, Introduzca su dirección de correo electrónico " 
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(correoelectronico)) {
            errors.correoelectronico = "Dirección de correo electrónico inválida"
        }

        // if (!dninie) { errors.dninie = "* Por favor, Introduzca su dni / nie" }
        // if (!fechanacimiento) { errors.fechanacimiento = "* Por favor, introduzca su fecha de nacimiento" }
        // if (!ocupacion) { errors.ocupacion = "* Por favor, introduzca su ocupación" }        
        // if (!curriculum) { errors.curriculum = "* Por favor, seleccione un archivo para su curriculum vitae" }
        // if (!carta) { errors.carta = "* Por favor, seleccione un archivo para su carta de motivación" }

        
        // if (!telefono) { 
        //     errors.telefono = "* Por favor, Introduzca su número de telefono" 
        // } else if (!/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/.test(telefono)) {
        //     errors.telefono = "Número de telefono inválida"
        // }

        return errors;
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLFormElement>) => { 
        if (event.target.id === "curriculum") {
            setCurriculumVitae(event.target.files[0])

        }

        if (event.target.id === "carta") {
            setCartaDeMotivacion(event.target.files[0])
        }
    }
    
    const convertToBase64 = (file: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event: ProgressEvent<FileReader>) => { 
            if (typeof event.target?.result === "string") {
                return resolve(event.target?.result.split(',').pop())
            }
        };
        reader.onerror = reject;
    })
    
    const uploadDocuments = async (files: File[]): Promise<DocumentResponse[] | undefined> => {
        try {
            let docs: DocumentRequest[] = [];

            for (const file of files) {
                if (file.type) {
                    const fileToBase64 = await convertToBase64(file);
    
                    const fileObject: DocumentRequest = {
                        documentName: file.name,
                        documentType: file.type,
                        documentData: fileToBase64 as string
                    }
    
                    docs.push(fileObject);
                }
            }
            
            if (docs.length > 0) {
                const savedDocuments = await InscriptionService.saveDocuments(docs);
                return savedDocuments;
            }

            return [];
        } catch (err) {
            const error = err as AxiosError
            setErrors(error.message)
        }
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validate={validate}
                onSubmit={(values: FormularioPublicoFormikValues) => {
                    uploadDocuments([curriculumVitae, cartaDeMotivacion]).then((docs) => {
                        let curriculumResponse: string = "";
                        let cartaDeMotivacionResponse: string = "";

                        if (docs && docs.length > 0 && curriculumVitae.type) {
                            curriculumResponse = docs.filter(doc => doc.documentName === curriculumVitae.name)[0].documentUrl
                        }

                        if (docs && docs.length > 0 && cartaDeMotivacion.type) {
                            cartaDeMotivacionResponse = docs.filter(doc => doc.documentName === cartaDeMotivacion.name)[0].documentUrl
                        }

                        const { curriculum, carta, aceptarcondiciones, ...props } = values
                        registerUser({
                            ...props,
                            genero: 33223, 
                            cartaDeMotivacion: cartaDeMotivacionResponse, 
                            curriculumVitae: curriculumResponse,
                            aceptarcondiciones: values.aceptarcondiciones.length === condiciones.length ? "on" : "off"
                        })
                    })
                }}
            >
                {({ values, touched, errors }: FormikProps<FormularioPublicoFormikValues>): React.ReactElement => (
                    <Form onChange={handleOnChange}>
                        <div className="form-section">
                            {formularioPublico.map(field => (
                                <div className="form-input" key={field.name}>
                                    <label htmlFor={field.name} className={`label ${field.type === 'file' ? 'file' : ''}`}>
                                        <span>
                                            {field.label}{!field.optional ? <span> *</span> : <></>}
                                        </span>
                                        <Field 
                                            id={field.name}
                                            name={field.name}
                                            type={field.type}
                                            value={values[field.name as eachField]}
                                            placeholder={field.placeholder}
                                            className={`${touched[field.name as eachField] ? 'touched' : ''}${errors[field.name as eachField] ? ' error' : ''}`}
                                            accept={field.type === 'file' ? "application/pdf" : ""}
                                        />
                                        {field.type === 'file' && 
                                            <span className="file-selected">
                                                {field.name === "curriculum" ? (curriculumVitae.name || "Seleccionar archivo...")
                                                    : field.name === "carta" ? (cartaDeMotivacion.name || "Seleccionar archivo...") : ""}
                                            </span>
                                        }
                                    </label>
                                    {errors[field.name as eachField] && touched[field.name as eachField] && <span className="form-error">{errors[field.name as eachField]}</span>}
                                </div>
                            ))}
                        </div>

                        <div className={`form-mapa${touched.direccion ? ' touched' : ''}${errors.direccion ? ' error' : ''}`}>
                            <Map form={true} />
                        </div>
                        {errors.direccion && touched.direccion && <span className="form-error">{errors.direccion}</span>}

                        {condiciones.length > 0 && (
                            <div className="form-condiciones" role="group" aria-labelledby="checkbox-group">
                                {condicionTextos.length > 0 && <Condiciones condiciones={condicionTextos} tipo="condiciones" />}
                                {condicionEnlances.length > 0 && <Condiciones condiciones={condicionEnlances} tipo="enlaceWeb" />}
                                {condicionDocumentos.length > 0 && <Condiciones condiciones={condicionDocumentos} tipo="documento" />}
                            </div>
                        )}
                        {errors.aceptarcondiciones && touched.aceptarcondiciones && <span className="form-error">{errors.aceptarcondiciones}</span>}

                        <div className="form-condiciones">
                            <div className="form-condicion">
                                <label>
                                    <Field 
                                        id="envio"
                                        name="envio"
                                        type="checkbox"
                                        defaultValue={values.envio}
                                    /> {' '}
                                    <span>
                                        Acepta el envío de la Newsletter de I+De de CODESPA
                                    </span>
                                </label>
                            </div>
                        </div>

                        <div className="form-btn">
                            <Button text="INSCRIBETE" type="submit" />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
}

export default FormularioPublico;
