import Axios, { GenericResponse } from "./Axios";

export interface UserReduxResponse {
    id: string
    firstName: string
    lastName: string
    token: string
    lastPassUpdate: string
    idioma: string
}

export interface UserResponse extends UserReduxResponse {
    email: string
    telephone: string
    password: string
    confirmpassword: string
    organizacion: string
    organizacion_name: string
    organization_cross: string
    nColegiado: string
    especiality: string
    oldpassword: string
    processKey: string
    processName: string
    dni: string
    direccion: string
    localidad: string
    provincia: string
    fechaNacimiento: string
    formacion: string
    catSalarial: string
    numPagas: string
    salBruto: string
    salNeto: string
    retIRPF: string
    bonus: string
    costeEmpresa: string
    cuentaBancaria: string
    concatGroups: string
    pictureSet: boolean,
    groups: [],
    blockedFields: [],
    processes: [],
    signals: [],
}

interface LoginResponse extends GenericResponse {
    user: UserResponse
    signals: []
}

class LoginService {

    private axios: any;

    constructor() {
        this.axios = Axios;
    }

    private static INSTANCE = new LoginService();

    public static getInstance(): LoginService {
        return LoginService.INSTANCE;
    }

    public async login({ id, password }: { id: string, password: string }): Promise<UserResponse> {
        try {
            const response: { data: LoginResponse } = await this.axios({
                url: "login/validate",
                method: "POST",
                data: { user: { id, password } }
            })

            if (!Object.hasOwn(response, "data")) {
                throw new Error("Error de servicio: Estamos trabajando en ello, inténtelo más tarde")
            }

            if (!(response.data.user.id.length > 0)) {
                throw new Error("Este usuario no existe");
            }

            return response.data.user
        } catch (error) {
            throw new Error(error as string)
        }
    }
}

export default LoginService.getInstance()