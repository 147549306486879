import React, { useEffect, useState } from "react";
import CodespaLogo from '../../assets/codespa-logo.svg';
import { MdOutlineAccessTimeFilled } from 'react-icons/md';

export default function CuestionarioWelcomeScreen({ welcomeScreen, complete, onClick, title, body, estimatedTime }: {
    welcomeScreen: boolean
    complete: boolean
    onClick: () => void
    title: string | undefined
    body: string | undefined
    estimatedTime: string | undefined
}) {
    const [bodyOpacity, setBodyOpacity] = useState(0);
    const [bodyTranslate, setBodyTranslate] = useState('translateY(10%)');

    useEffect(() => {
        const updatedOpacity = setTimeout(() => {
            setBodyOpacity(1)
            setBodyTranslate('translateY(0)')
        }, 1000);

        return () => {
            clearTimeout(updatedOpacity);
        }
    }, [])

    return (
        <main
            className="welcome-screen"
            style={{
                opacity: welcomeScreen && !complete ? '1' : '0',
                pointerEvents: welcomeScreen && !complete ? 'auto' : 'none'
            }}
        >
            <section className="welcome-screen__img">
                <img src={CodespaLogo} alt="logo" />
            </section>

            <section
                className="welcome-screen__text"
                style={{
                    opacity: bodyOpacity,
                    transform: bodyTranslate,
                    transition: 'opacity .5s ease-out, transform 1s ease-out'
                }}
            >
                <h1 className="welcome-screen__text__header">
                    {title}
                </h1>

                <p className="welcome-screen__text__body">
                    {body}
                </p>

                <div className="welcome-screen__text__action">
                    <div className="action">
                        <button
                            className="action-btn"
                            onClick={() => onClick()}
                        >
                            <span>Claro que sí</span>
                        </button>
                    </div>
                    <div className="seconds">
                        <MdOutlineAccessTimeFilled />
                        <span>Tarda {estimatedTime?.split(',')[0]} segundos</span>
                    </div>
                </div>
            </section>
        </main>
    )
}
