import Axios, { GenericResponse, InscriptionResponse } from "./Axios";

export interface InscriptionForm {
    name: string;
    type: string;
    label: string;
    placeholder: string;
    optional?: boolean
}

export interface InscriptionRequest {
    dninie: string
    nombre: string
    apellidos: string
    direccion: string
    correoelectronico: string
    genero: number
    fechanacimiento: string
    telefono: string
    nombreempresa: string
    departamento: string
    ocupacion: string
    envio: boolean | string
    idinscripcion?: string
    cursotaller?: string
    curriculumVitae?: string
    cartaDeMotivacion?: string
    aceptarcondiciones: "off" | "on"
}

export interface DocumentRequest {
    documentName: string
    documentType: string
    documentData: string
}

export interface DocumentResponse {
    documentName: string
    documentType: string
    documentData: string
    documentUrl: string
}

export interface DocumentResponseService extends GenericResponse {
    documentUrls: DocumentResponse[]
}

class InscriptionService {
    private axios: any;
    private token: string = "EmMMa0VJm6rRjfzvxKE8CUXXX"
    
    constructor () {
        this.axios = Axios;
    }

    private static INSTANCE: InscriptionService = new InscriptionService();

    public static getInstance(): InscriptionService {
        return InscriptionService.INSTANCE
    }

    public async register(payload: InscriptionRequest): Promise<InscriptionResponse> {
        try {
            const response: { data: GenericResponse } = await this.axios({
                url: `interface/prcInscripcion/frmInscripcion/${this.token}`,
                data: payload,
                method: 'PUT'
            })

            if (!(response.data.context.blockChainHash.length > 0)) {
                throw new Error("Inscripción inválida")
            }
            
            return response.data.context.values as InscriptionResponse;
        } catch (error) {
            throw new Error(error as string);
        }
    }

    public async saveDocuments(data: DocumentRequest[]): Promise<DocumentResponse[]> {
        try {
            const response: { data: DocumentResponseService } = await this.axios({
                url: `interface/upload`,
                data: {
                    token: this.token,
                    document: data
                },
                method: 'POST'
            })

            if (!response.data) {
                throw new Error("File not accepted")
            }

            if (response.data.context.criticalError) {
                throw new Error(response.data.context.criticalMessage)
            }

            return response.data.documentUrls;
        } catch (error) {
            throw new Error(error as string);
        }
    }
}

export default InscriptionService.getInstance();