import React from 'react';
import '../styles/components.scss';

const Button = ({ text, onClick, className, textDom, icon, ...props }: {
    text?: string
    icon?: React.ReactNode
    textDom?: React.ReactNode
    onClick?: () => void,
    className?: string
    [x: string]: any
}) => (
    <button
        className={`cd-btn${className ? ' ' + className : ''}`}
        onClick={() => {
            if (onClick) onClick();
        }}
        {...props}
    >
        
        {icon && <img src={icon as string} alt="" />}
        {text && text}
        {textDom && textDom}
    </button>
);

export default Button