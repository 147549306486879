import React from "react";
import Codespa from '../../assets/codespa-logo.svg';
import { Link, useLocation } from "react-router-dom";
import Routes from "../Routes";
import Footer from "../../components/Footer";
import '../../styles/inscripcion.scss';

export default function InscripcionAceptada() {
    const { state } = useLocation();

    return (
        <div className="App-aceptada">
            <img src={Codespa} alt="logo de compaña" />

            <div className="App-aceptada__body">
                <div className="App-aceptada__body__inner">
                    <h2>Solicitud Inscripción curso - Pendiente de confirmar </h2>

                    <p>
                        Hola {state?.name}, hemos recibido tu solicitud de inscripcion para el curso {state?.course}, estamos confirmando disponibilidad. Tan pronto sea posible recibirás tu confirmación.
                    </p>
                    <br />
                    <br />
                    <Link to={Routes.INICIO}>ir a la página de inicio</Link>
                </div>
            </div>

            <Footer />
        </div>
    );
}
