import { createSlice } from "@reduxjs/toolkit"

const initialState: {
    id: string
    firstName: string
    lastName: string
    token: string
    lastPassUpdate: string
    idioma: string
} = {
    id: "",
    firstName: "",
    lastName: "",
    token: "",
    lastPassUpdate: "",
    idioma: ""
}

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        createUser: (state, action) => {
            state = action.payload
            return state;
        },

        emptyUser: (state) => {
            state = initialState;
            return state;
        }
    }
})

export const { createUser, emptyUser } = userSlice.actions
export default userSlice.reducer;