import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux';
import Table, { TableBody, TableButtons } from '../../components/Table';
import { AppDispatch, RootState } from '../../redux/store';
import Routes, { useRoutes } from '../Routes';
import ModuleService, { AutoEvaluacionResponse, ModuleResponse } from '../../service/ModuleService';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowButtons from './ArrowButtons';
import { createModule } from '../../redux/slice/ModuleSlice';
import Modal from '../../components/modal/Modal';
import { Calificaiones } from '../../service/QuestionService';
import { updateAnswers } from '../../redux/slice/AnswerSlice';
import { BsCheckSquareFill } from 'react-icons/bs';
import { ImCross } from "react-icons/im";
import '../../styles/contenidos.scss';
import CourseService, { CourseResponse } from '../../service/CourseService';
import { updateMyCourses } from '../../redux/slice/CourseSlice';
import { AxiosError } from 'axios';

function Evaluaciones({ tituloWeb, autoevaluaciones, token, answers, handleModule, handleAnswers, handleMyCourses } : { 
    tituloWeb: string
    token: string
    autoevaluaciones: AutoEvaluacionResponse[] 
    answers: { resultado: number, calificaciones: Calificaiones[] }
    handleModule: (module: ModuleResponse) => any 
    handleAnswers: (param: any) => any
    handleMyCourses: (param: CourseResponse[]) => any
}) {
    const { setErrors, setTabs, logout } = useRoutes()
    const navigate = useNavigate()
    const { pathname, state } = useLocation()
    const urlArray = pathname.split('/')
    const resultado = Number.isInteger(answers.resultado) 
        ? answers.resultado * 100
        : parseFloat(Number(answers.resultado * 100).toFixed(2)) 

    const updateUrl = (id: string) => {
        const { course_name, module_list } = state; 
        navigate(
            Routes.EVALUACIONES.replace(':id', urlArray[3]).replace(':id_eval', id),
            { state: { course_name, module_list }}
        )
    }

    const updateAnswers = async () => {
        try {
            const result = await CourseService.fetchMyCourses(token)
            if (result.cursos.length === 0) {
                throw new Error("No tienes cursos")
            }
    
            const updatedResult = result.cursos.map(curso => {
                let currentModule: number | undefined; 
    
                curso.modulos.forEach((module, ind) => {
                    // check if we already have a currentModule
                    if (currentModule !== undefined) {
                        module.access = false
                        return
                    };
                    
                    // check completed
                    if (module.examenesCompletados === module.autoevaluaciones.length) {
                        module.access = true
                    } else if (module.examenesCompletados < module.autoevaluaciones.length) {
                        module.access = true
                        currentModule = ind;
                        return
                    }
                })
    
                return curso;
            })
    
            handleMyCourses(updatedResult)
            handleAnswers({ calificaciones: [], resultado: answers.resultado })
        } catch (err) {
            const error = err as AxiosError

            if (error.message.includes("403")) {
                logout()
            } else {
                setErrors(error.message)
            }
        }
    }

    useEffect(() => {
        if (state !== null) {
            const { course_name, module_list } = state; 

            setTabs([
                { name: state?.course_name as string, url: Routes.MODULO.replace(':id', urlArray[3]) },
                { name: tituloWeb, url: pathname, state: { course_name, module_list } }
            ])
        }
    }, [pathname])

    return (
        <Fragment>
            <main className="App-main App-evaluaciones">
                <h2>{tituloWeb}</h2>

                <section className="table-evaluaciones">
                    <Table header="Autoevaluaciones">
                        {autoevaluaciones.map(autoevaluacion => (
                            <TableBody 
                                key={autoevaluacion.id} 
                                bodyTitle={autoevaluacion.tituloportal}
                                bodyText={autoevaluacion.descripcionportal}
                            >
                                <TableButtons 
                                    buttons={[{
                                        id: 0, 
                                        text: "Comenzar", 
                                        onClick: () => {
                                            ModuleService.fetchModules(urlArray[5], token)
                                                .then(result => {
                                                    handleModule(result.mmodulo)
                                                    const currentResult = result.mmodulo.autoevaluaciones.filter(each => each.taskId === autoevaluacion.taskId)

                                                    if (currentResult.length === 0) {
                                                        setErrors("")
                                                        return
                                                    }

                                                    if (currentResult[0].cuestionario === null) {
                                                        setErrors("No hay cuestionario en este curso")
                                                        return
                                                    }

                                                    if (currentResult[0].noMoreTries) {  
                                                        setErrors("Se ha superado el numero de intentos")
                                                    } else {
                                                        localStorage.setItem("task_id", JSON.stringify(autoevaluacion.taskId))
                                                        window.open(`${window.location.origin}/#${Routes.CUESTIONARIO}`, "_blank")
                                                    }
                                                })
                                        }
                                    }]}
                                />
                            </TableBody>
                        ))}
                    </Table>
                </section>
            </main>
            
            <ArrowButtons 
                moduleId={urlArray[5]}
                moduleList={state.module_list}
                updateUrl={updateUrl}
                setErrors={setErrors}
                handleModule={handleModule}
                token={token}
            />

            <Modal 
                show={answers.calificaciones.length > 0 ? ' true' : ''} 
                backgroundClick={true} 
                onClick={updateAnswers}
                customClass='modal-answers'
            >
                <div className="modal-title">
                    Resultado autoevaluación
                </div>

                <div className="modal-body">
                    {answers.calificaciones.map(answer => (
                        <div className="modal-list" key={answer.idPregunta}>
                            <p>{answer.pregunta}</p>
                            <div className="modal-list__answer">
                                <div className="modal-list__icon">
                                    {answer.respuesta === answer.respuestaCorrecta 
                                        ? <BsCheckSquareFill size={32} color="green" />
                                        : <ImCross size={27} color="red" />
                                    }
                                    
                                </div>
                                <p>{answer.respuesta}</p>
                            </div>

                            {answers.calificaciones.length > 1 ? <hr /> : <></>}
                        </div>
                    ))}
                </div>

                <div 
                    className={`modal-footer${resultado > 50 ? " superado" : " nosuperado"}`}
                    onClick={updateAnswers}
                >
                    {resultado > 50 
                        ? <><div>{resultado}%</div><div>PRUEBA SUPERADA</div></> 
                        : <><div>{resultado}%</div><div>PRUEBA NO SUPERADA</div></>}
                </div>
            </Modal>
        </Fragment>
    );
}
    
const mapStateToProps = (state: RootState) => ({
    tituloWeb: state.module.tituloWeb,
    autoevaluaciones: state.module.autoevaluaciones,
    token: state.user.token,
    answers: state.answer
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    handleModule: (module: ModuleResponse) => dispatch(createModule(module)),
    handleAnswers: (param: any) => dispatch(updateAnswers(param)),
    handleMyCourses: (param: CourseResponse[]) => dispatch(updateMyCourses(param)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Evaluaciones)