import React from 'react'
import { EachQuestion } from '../service/QuestionService';

export default function Suggestions({ updateAnswer, pregunta }: {
    updateAnswer: (idpregunta: string, pregunta?: string, respuesta?: string, idrespuesta?: string) => void
    pregunta: EachQuestion
}) {
    return (
        <div className="observation">
            <textarea
                className="complaints"
                placeholder="Escribe tu mensaje aquí..."
                onInput={(e) => {
                    const { currentTarget } = e;
                    currentTarget.setAttribute("style", "height: 0px");
                    currentTarget.setAttribute("style", "height:" + (currentTarget.scrollHeight) + "px");

                    if (e.currentTarget.value.length > 0) {
                        updateAnswer(pregunta.idpregunta, pregunta.enunciado, e.currentTarget.value)
                    } else {
                        updateAnswer(pregunta.idpregunta)
                    }
                }}
            ></textarea>
        </div>
    )
}
