import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import ErrorPage from './ErrorPage';
import { store, persistor } from './redux/store';
import Root from './routes/Root';
import Routes from './routes/Routes';
import Cuestionario from './routes/cuestionario/Cuestionario';
import CursosDisponibles from './routes/cursos disponibles/CursosDisponibles';
import Home from './routes/home/Home';
import Inscripcion from './routes/inscripcion/Inscripcion';
import InscripcionAceptada from './routes/inscripcion/InscripcionAceptada';
import MisCursos from './routes/mis cursos/MisCursos';
import Contenidos from './routes/modulo/Contenidos';
import Evaluaciones from './routes/modulo/Evaluaciones';
import Modulo from './routes/modulo/Modulo';

const router = createHashRouter([
    {
        path: Routes.INICIO,
        element: <Root />,
        errorElement: <ErrorPage />        
    },
    {
        path: Routes.HOME,
        errorElement: <ErrorPage />,
        element: <Home />,
        children: [
            {
                path: Routes.CURSOS_DISPONIBLES,
                element: <CursosDisponibles />
            },
            {
                path: Routes.INSCRIPCION,
                element: <Inscripcion />,
            },
            {
                path: Routes.CURSOS,
                element: <MisCursos />
            },
            {
                path: Routes.MODULO,
                element: <Modulo />
            },
            {
                path: Routes.EVALUACIONES,
                element: <Evaluaciones />
            },
            {
                path: Routes.CONTENIDOS,
                element: <Contenidos />
            },
        ]
    },
    {
        path: Routes.INSCRIPCION_ACEPTADA,
        element: <InscripcionAceptada />,
        errorElement: <ErrorPage />
    },
    {
        path: Routes.CUESTIONARIO,
        element: <Cuestionario />,
        errorElement: <ErrorPage />
    }
])

function App() {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <RouterProvider router={router} />
            </PersistGate>
        </Provider>
    )
}

export default App
