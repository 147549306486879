import React from 'react'
import { FiCheck } from 'react-icons/fi';

const QuestionOption = ({ letter, option, setClicked, clicked }: {
    letter: string,
    option: string,
    clicked: string
    setClicked: any
}) => {
    const checkedClicked: boolean = clicked === letter;

    return (
        <div
            className={`option-div ${checkedClicked ? 'animate' : ''}`}
            onClick={() => {
                setClicked((current: string) => {
                    if (current === letter) {
                        return '';
                    }
                    return letter;
                })
            }}
        >
            <div className="option-div__body">
                <div className={`option-div__body__letter ${checkedClicked ? 'animate' : ''}`}>
                    {letter}
                </div>
                <div className="option-div__body__text">
                    {option}
                </div>
            </div>
            <div
                className="option-div__icon"
                style={{ opacity: checkedClicked ? 1 : 0 }}
            >
                <FiCheck size={20} fontWeight={900} />
            </div>
        </div>
    )
}

export default QuestionOption