import React, { useState } from 'react';
import { GrStar } from 'react-icons/gr';
import { EachQuestion } from '../service/QuestionService';

export default function Ratings({ incrementCount, updateAnswer, pregunta }: {
    incrementCount: (ok?: string) => void
    updateAnswer: (idpregunta: string, pregunta?: string, respuesta?: string, idrespuesta?: string) => void
    pregunta: EachQuestion
}) {
    const stars = Array(10).fill(1).map((_, i) => i+1);
    const [starCount, setStarCount] = useState(-1);
    const [clicked, setClicked] = useState(-1);

    return (
        <div className="rating">
            {stars.map((star, i) => (
                <div
                    key={star}
                    className="each-star"
                    onMouseOver={() => {
                        if (starCount !== star) {
                            setStarCount(star);
                        }
                    }}
                    onMouseLeave={() => {
                        if (starCount !== -1) {
                            setStarCount(-1);
                        }
                    }}
                    onClick={() => {
                        if (clicked !== star) {
                            setClicked(star)
                            incrementCount('ok')
                            updateAnswer(pregunta.idpregunta, pregunta.enunciado, star.toString())
                        } else {
                            updateAnswer(pregunta.idpregunta)
                            setClicked(-1)
                        }
                    }}
                >
                    <GrStar
                        fill={clicked >= star ? "rgba(55, 64, 74)" : starCount > i ? 'rgba(55, 64, 74, .1)' : 'transparent'}
                        stroke="rgba(55, 64, 74)"
                        strokeWidth={1}
                        size={60}
                        className={clicked === star ? ' animate' : ''}
                    />
                    <p>{star}</p>
                </div>
            ))}
        </div>
    )
}