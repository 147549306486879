import axios from "axios";

interface Valuestructure {
    value: string
    valueName: string
    id: string
    fieldName: string
    type: string
}

export interface InscriptionResponse {
    apellidos: Valuestructure
    aceptarcondiciones: Valuestructure
    curriculumvitae: Valuestructure
    cursotaller: Valuestructure
    direccion: Valuestructure
    ocupacion: Valuestructure
    correoelectronico: Valuestructure
    nombre: Valuestructure
    dninie: Valuestructure
    envio: Valuestructure
    cartademotivacion: Valuestructure
    idinscripcion: Valuestructure
    fechanacimiento: Valuestructure
    blockchain: Valuestructure
    nombreempresa: Valuestructure
    genero: Valuestructure
    departamento: Valuestructure
    telefono: Valuestructure
}

export interface AnswerResponse {
    idcuestionariocurso: Valuestructure
    fecha: Valuestructure
    idcuestionario: Valuestructure
    blockchain: Valuestructure
    cuestionariotaskId: Valuestructure
    idresultcuestionario: Valuestructure
    usuario: Valuestructure
    idcurso: Valuestructure
}

export interface GenericResponseWithoutContext {
    criticalError: boolean
    blockChainHash: string
    blockChainList: null
    criticalMessage: string
    criticalType: string
    mensajesError: any[]
    listaPermisos: {
        ver_tarea: {
            id: string
            type: null
            valor: number
        }
        ver_usuario: {
            id: string
            type: null
            valor: number
        }
        crear_tarea: {
            id: string
            type: null
            valor: number
        }
        iniciar_proceso: {
            id: string
            type: null
            valor: number
        }
        comentar_tarea: {
            id: string
            type: null
            valor: number
        }
        asignar_tarea: {
            id: string
            type: null
            valor: number
        }
        cancelar_proceso: {
            id: string
            type: null
            valor: number
        }
        ver_organizaciones: {
            id: string
            type: null
            valor: number
        }
        ver_rol: {
            id: string
            type: null
            valor: number
        }
    }
    entities: {
        135: string
        136: string
        151: string
        141: string
    }
    values: InscriptionResponse | AnswerResponse | {}
    funcionalidades: {
        roles: boolean
        usuarios: boolean
        pacientes: boolean
        traza: boolean
        estadistica: boolean
        entidades: boolean
        calendario: boolean
        eficiencias: boolean
        organizaciones: boolean
        tablas: boolean
        coleccionesExternas: boolean
        gestorServicios: boolean
        plantillas: boolean
        trabajadores: boolean
        administrador: boolean
        menus: boolean
        exportforms: boolean
        importforms: boolean
        templatefields: boolean
        appfunctionalities: {}
    }
    openThreads: number
    openConnections: number
}

export interface GenericResponse {
    context: GenericResponseWithoutContext
}

export const generateFileUrl = (file: string) => `${process.env.REACT_APP_BASE_URL}/docs/${file}`;

export default axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/activiti-app/services/`,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
})
