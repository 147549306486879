import { createSlice } from "@reduxjs/toolkit";
import { Calificaiones } from "../../service/QuestionService";

const initialState: {
    calificaciones: Calificaiones[],
    resultado: number
} = {
    calificaciones: [],
    resultado: 0
}

export const AnswerSlice = createSlice({
    name: "answer",
    initialState,
    reducers: {
        updateAnswers: (state, action) => {
            state = action.payload
            return state
        }
    }
})

export const { updateAnswers } = AnswerSlice.actions
export default AnswerSlice.reducer