import { Fragment, useEffect, useState } from 'react';
import Table, { TableBody, TableButtons } from '../../components/Table';
import { ContenidosValue, ModuleResponse } from '../../service/ModuleService';
import { generateFileUrl } from '../../service/Axios';
import { AppDispatch, RootState } from '../../redux/store';
import { connect } from 'react-redux';
import '../../styles/contenidos.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import Routes, { useRoutes } from '../Routes'
import ArrowButtons from './ArrowButtons';
import { createModule } from '../../redux/slice/ModuleSlice';

enum ContentType {
    Video = "Video", Documento = "Documento", Web = "Contenido Web"
}

enum ETipologyStage {
    presentacion="33186",
    guia="33187",
    tecnicos="33188",
    lecturas= "33189", 
    actividades="33190",
};

function Contenidos({ tituloWeb, contenidos, token, handleModule }: { 
    tituloWeb: string
    contenidos: ContenidosValue[]  
    token: string
    handleModule: (module: ModuleResponse) => any
}) {
    const { setErrors, setTabs } = useRoutes()
    const navigate = useNavigate()
    const { pathname, state } = useLocation()
    const urlArray = pathname.split('/')

    const [tipology, setTipology] = useState<{
        [x: string]: ContenidosValue[]
    }>({});
    
    const updateUrl = (id: string) => {
        const { course_name, module_list } = state; 
        navigate(
            Routes.CONTENIDOS.replace(':id', urlArray[3]).replace(':id_cont', id),
            { state: { course_name, module_list }}
        )
    }

    useEffect(() => {
        // Sort by topology
        const eachTipology: {
            [x: string]: ContenidosValue[]
        } = {}

        contenidos.forEach(content => {
            const currentTipology = content.contenido.codigotipologia

            if (eachTipology.hasOwnProperty(currentTipology)) {
                eachTipology[currentTipology].push(content);
            } else {
                eachTipology[currentTipology] = [content]
            }
        })

        setTipology(eachTipology);        
    }, [contenidos])

    const TipologyStage = ({ stage }: { stage: ContenidosValue[] }) => {
        if (stage === undefined) {
            return <></>;
        }
        
        return (
            <Table header={stage[0].contenido.tipologia}>
                {stage.map(eachContent => <ContenidosBody tipology={eachContent} key={eachContent.blockchain} />)}
            </Table>
        )
    }

    useEffect(() => {
        if (state !== null) {
            const { course_name, module_list } = state; 
            setTabs([
                { name: state?.course_name as string, url: Routes.MODULO.replace(':id', urlArray[3]) },
                { name: tituloWeb, url: pathname, state: { course_name, module_list } }
            ])
        }
    }, [pathname])

    return  (
        <Fragment>
            <main className="App-main App-contenidos">
                <h2>{tituloWeb}</h2>

                <section className="table-contenidos">
                    <TipologyStage stage={tipology[ETipologyStage.presentacion]} />
                    <TipologyStage stage={tipology[ETipologyStage.guia]} />
                    <TipologyStage stage={tipology[ETipologyStage.tecnicos]} />
                    <TipologyStage stage={tipology[ETipologyStage.actividades]} />
                    <TipologyStage stage={tipology[ETipologyStage.lecturas]} />
                </section>
            </main>

            <ArrowButtons 
                moduleId={urlArray[5]}
                moduleList={state.module_list}
                updateUrl={updateUrl}
                setErrors={setErrors}
                handleModule={handleModule}
                token={token}
            />
        </Fragment>
    )
}

const ContenidosBody = ({ tipology }: { tipology: ContenidosValue }) => {
    const CHECK_IF_VIDEO = tipology.contenido.formato === ContentType.Video
    const CHECK_IF_DOCUMENT = tipology.contenido.formato === ContentType.Documento
    const CHECK_IF_URL = tipology.contenido.formato === ContentType.Web
    const youtubeLink = CHECK_IF_VIDEO ? tipology.contenido.enlaceurl.split("/") : []

    const checkTipologyFile = (tipologyFile: string | null) => {
        const fileTypes = ['.bmp', '.jpg', '.jpeg', '.png.', '.svg', '.bmp']

        if (tipologyFile === null) return false;
        return fileTypes.some(fileTypes => tipologyFile.indexOf(fileTypes) !== -1);
    }

    return (
        <TableBody 
            bodyTitle={tipology.tituloportal} 
            bodyText={tipology.descripcionportal}
            customRightDom={CHECK_IF_VIDEO && (
                <iframe width="100%" height="340" 
                    src={`https://www.youtube.com/embed/${youtubeLink[youtubeLink.length - 1 ]}`} 
                    title={tipology.tituloportal} 
                    allow="autoplay">
                </iframe>
            )}
            image={checkTipologyFile(tipology.contenido.fichero) ? generateFileUrl(tipology.contenido.fichero) : undefined}
        >
            <TableButtons 
                buttons={
                    CHECK_IF_DOCUMENT ? [{ 
                        id: 0, text: "Descargar", 
                        onClick: () => window.open(generateFileUrl(tipology.contenido.fichero), '_blank', 'noreferrer') 
                    }] : 
                    CHECK_IF_URL ? [{ 
                        id: 0, text: "Ver", 
                        onClick: () => window.open(tipology.contenido.enlaceurl, '_blank', 'noreferrer')
                    }] : []
                }
            />
        </TableBody>
    )
}

const mapStateToProps = (state: RootState) => ({
    tituloWeb: state.module.tituloWeb,
    contenidos: state.module.contenidos,
    token: state.user.token
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    handleModule: (module: ModuleResponse) => dispatch(createModule(module))
})

export default connect(mapStateToProps, mapDispatchToProps)(Contenidos);

{/* {Object.keys(tipology).map(each => (
    <Table 
        header={tipology[each][0].contenido.tipologia}
        key={tipology[each][0].contenido.tipologia}
    >
        {tipology[each].map(eachTipology => <ContenidosBody tipology={eachTipology} />)}
    </Table>
))} */}