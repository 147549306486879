import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import ModuleService from "../../service/ModuleService";
import { AxiosError } from "axios";

export default function ArrowButtons({ moduleId, moduleList, token, handleModule, updateUrl, setErrors }: {
    moduleId: string,
    moduleList: string[]
    updateUrl: (param1: string) => void,
    setErrors: React.Dispatch<React.SetStateAction<string>>
    handleModule: Function,
    token: string
}) {
    const currentPosition = moduleList.indexOf(moduleId)
    const arrowLeft = currentPosition === 0
    const arrowRight = currentPosition === (moduleList.length - 1)

    const onBtnClick = async (id: string) => {
        try {
            const result = await ModuleService.fetchModules(id, token)
            handleModule(result.mmodulo);
            
            updateUrl(id)

        } catch (err) {
            const error = err as AxiosError
            setErrors(error.message)
        }
    }

    return (
        <div className="arrow-btns">
            <button 
                className={`cd-btn${arrowLeft ? ' no-arrow' : ''}`}
                onClick={!arrowLeft ? () => {
                    onBtnClick(moduleList[(currentPosition - 1)])
                } : undefined}>
                <IoIosArrowBack size={27}  />
            </button>

            <button 
                className={`cd-btn${arrowRight ? ' no-arrow' : ''}`}
                onClick={!arrowRight ? () => {
                    onBtnClick(moduleList[currentPosition + 1])
                } : undefined}>
                <IoIosArrowForward size={27}  />
            </button>
        </div>
    );
}
