import React from "react";
import Button from "../Button";
import '../../styles/components.scss'
import Modal from "./Modal";

interface ModalType {
    show: string, 
    setShow: React.Dispatch<React.SetStateAction<string>>
    buttonType: "green" | "yellow"
    customAction?: () => void
}

export default function ErrorModal({ show, setShow, buttonType, customAction }: ModalType) {
    const onClick = () => {
        setShow("")
        if (customAction) { customAction() }
    }

    return (
        <Modal 
            show={show} 
            customClass="error-md" 
            onClick={onClick} 
            backgroundClick={true}
        >
            <p>{show}</p>
            {buttonType === "green" 
                ? <Button text="Regresar" onClick={onClick} className="error-btn" />
                : <button className="action-btn" onClick={onClick}><span>Regresar</span></button>} 
        </Modal>
    )
}
