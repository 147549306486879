import React from "react";
import { FaArrowRight } from 'react-icons/fa';
import { FiCheck } from 'react-icons/fi';

export default ({ customClass, title, questionNumber, instruction, children, observable, incrementCount, onComplete }: {
    customClass?: string
    title: string
    questionNumber: number
    instruction: string
    children: any
    observable?: boolean
    incrementCount?: Function
    onComplete?: Function
}) => (
    <div className="App-question">
        <div className={`App-question__inner${customClass ? ' ' + customClass : ''}`}>
            <div className="App-question-left">
                <span>{questionNumber}</span>
                <FaArrowRight />
            </div>
            <div className="App-question-right">
                <div className="App-question-text">
                    <div className="App-question-title">
                        {title}
                    </div>
                    <div className="App-question-body">
                        {instruction}
                    </div>
                </div>
                <div className="App-question-custom">
                    {children}
                </div>

                <div className={`App-question-btn${observable ? ' observe': ''}`}>
                    {!observable ? (
                        <button
                            className="action-btn"
                            onClick={() => {
                                if (incrementCount && customClass === "date-transform") {
                                    incrementCount();
                                } else if (incrementCount) {
                                    incrementCount('ok');
                                }
                            }}
                        >
                            <span>OK</span>
                            <FiCheck size={20} fontWeight={900} />
                        </button>
                    ) : (
                        <button
                            className="action-btn"
                            onClick={() => {
                                if (onComplete) onComplete()
                            }}
                        >
                            <span>Enviar</span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    </div>
)
