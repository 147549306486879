import React from "react";

export default function CuestionarioAppreciationScreen({ complete }: { complete: boolean }) {
    return (
        <div className={`completed${!complete ? ' is-visible' : ''}`}>
            <h1>Muchísimas gracias</h1>
            <br />
            <br />
            <p>Utilizaremos sus comentarios para mejorar el curso y nos pondremos en contacto con usted si necesitamos consultar algo.</p>
        </div>
    );
}
