import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Routes, { useRoutes } from '../Routes';
import Table, { TableBody, TableButtons, TableDate, TableMap } from '../../components/Table';
import '../../styles/contenidos.scss';
import { AppDispatch, RootState } from '../../redux/store';
import CourseService, { CourseResponse, CourseType } from '../../service/CourseService';
import { generateFileUrl } from '../../service/Axios';
import { useNavigate } from 'react-router-dom';
import { updateActiveCourses } from '../../redux/slice/CourseSlice';
import { AxiosError } from 'axios';

const CursosDisponibles = ({ courses, handleActiveCourses }: { courses: CourseResponse[], handleActiveCourses: (courses: CourseResponse[]) => any })  => { 
    const navigate = useNavigate()
    const { setErrors } = useRoutes()

    const fetchCourses = async () => {
        try {
            const response = await CourseService.fetchActiveCourses();

            if (!(response.cursos.length > 0)) {
                throw new Error("No hay cursos disponibles")
            }

            handleActiveCourses(response.cursos)
        } catch (err) {
            const error = err as AxiosError

            if (error.message.includes('403')) {
                setErrors("Credenciales incorrectas")
                return;
            }

            setErrors(error.message)
        }
    }

    useEffect(() => {
        if (courses.length === 0) {
            navigate(Routes.INICIO)
        } else {
            fetchCourses()
        }
    }, [])
    
    return (
        <main className="App-main App-disponibles">
            <h2>Consulta nuestra oferta de formación</h2>

            <section className="table-disponibles">
                <Table header="Cursos disponibles">
                    {courses.map(course => (
                        <TableBody 
                            key={course.idcurso} 
                            bodyTitle={course.tituloWeb} 
                            bodyText={course.introduccion} 
                            image={course.imagenWeb ? generateFileUrl(course.imagenWeb) : ''}
                        >
                            {course.tipo === CourseType.Presencial && (
                                <>
                                    <TableDate 
                                        fecha={course.fecha} 
                                        duration={course.duracion} 
                                        from={course.disponibleDesde} 
                                        until={course.disponibleHasta} 
                                    />

                                    <TableMap 
                                        lat={parseFloat(course.ubicacioncord.split(',')[0])}
                                        lng={parseFloat(course.ubicacioncord.split(',')[1])}
                                        direction={course.ubicacion}
                                    />
                                </>
                            )}

                            <TableButtons 
                                buttons={[{ 
                                    id: 0, 
                                    text: "Más informacion", 
                                    onClick: () => navigate(Routes.INSCRIPCION.replace(':name', course.tituloWeb), { state: { courseId: course.idcurso } }) 
                                }]}
                            />
                        </TableBody>
                    ))}
                </Table>
            </section>
        </main>
    )
}

const mapStateToProps = (state: RootState) => ({
    courses: state.courses.coursos_activos
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    handleActiveCourses: (param: CourseResponse[]) => dispatch(updateActiveCourses(param)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CursosDisponibles);
